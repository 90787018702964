import React, { useState, useEffect, useRef } from 'react';
// import './style/explore-more.scss';
import '../components/style/aboutus-header.scss'


const AboutHeader = () => {

    return (
        <>


            <div className='about-container'>
            <div className='wrapper-container'>
                <div className='about-header'>
                    <p className='about-para'>Home |<span> About us</span> </p>
                    <h2 className='about-us-header'>About us</h2>
                    <p className='about-us-para'> We help ambitious people reach their full potential and shine like never before!</p>
                </div>
            </div>
             </div>


        </>
    );
}
export default AboutHeader;