import React, { useEffect, useState, useRef } from "react";
import './style/coachMi-banner.scss';
import arrow from "../asset/images/testmibannerarrow.png";
import book from "../asset/images/testmibannerbook.png";
import institutionStaff from "../asset/images/young-business-latin-woman-isolated-white-background-showing-copy-space-palm-holding-another-hand-waist 2.png";


const Institution_Banner = ({institutionContactNowClick}) => {


  const handleInstitutionClick = () => {
    if (institutionContactNowClick) {
      institutionContactNowClick();
    }
  };


  const [textIndex, setTextIndex] = useState(0);

  const textArray = [
        "Unlock Your Potential: College Partnership Program with   <span style='color:#2D81F7;'>CentumAPP!</span>",
        "Partner with  <span style='color: #2D81F7;'>CentumAPP</span>  for Government Exam Prep!  ",
        "Connect, Learn, Succeed,   <span style='color: #2D81F7;'> CentumAPP's</span>  Program for College Students!  ",
        "Realize Dreams: Join    <span style='color: #2D81F7;'>CentumAPP's</span> College Partnership Initiative! ",
        "Guide to Careers,    <span style='color: #2D81F7;'>CentumAPP's</span>  Institutional Partner Program Leads the Path! ",
    
      ];

  useEffect(() => {
    const interval = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % textArray.length);
    }, 700);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="banner-headercontain-section">
        <div className="wrapper-container">
        <div className="banner-container-section">
        <p className="institution-paraLine">Institution Partnership Program</p>
          <div
            className="banner-text-section institution-banner-text"
            dangerouslySetInnerHTML={{ __html: textArray[textIndex] }}
          />

          <div className="banner-btn-section">
            <button className="ban-btn-clr-section institution-contact-btn" onClick={handleInstitutionClick}>Contact Now </button>
          </div>
        </div>

        <div className="banner-image-section">
          {/* <img src={rocket} alt="rocket" className="rocketWeb-image" /> */}
          <img src={arrow} alt="arrow" className="arrow-img-section institution-arrow" />
        </div>

        <div className="banner-right-section">
          <img src={institutionStaff} alt="arrow" className="second-img-imgage institution-image" />
          <img src={book} alt="arrow" className="grp-img-image institution-book" />
          {/* <img src={messageCoachmi} alt="arrow" className="message-img-image" /> */}
        </div>
      </div>
      </div>
    </>
  );
};
export default Institution_Banner;
