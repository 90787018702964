import React, { useState,useEffect } from 'react';
import colanImage from '../asset/images/colanImage.png';
import Slider from "react-slick";
import { Carousel, Card } from 'antd';
// import profImage from '../asset/images/Rectangle 3465398.png';
// import testmonialImage1 from '../asset/images/testimonialImage1.png';
import testmonialImage2 from '../asset/images/Ellipse 2.png';
import testmonialImage3 from '../asset/images/Ellipse 3.png';
import testmonialImage4 from '../asset/images/Ellipse 4.png';
import testmonialImage5 from '../asset/images/Ellipse 5.png';
import { useDispatch, useSelector } from 'react-redux';
import { getTestimonials } from '../components/reducers/WebsiteSlice';







const { Meta } = Card;


const Testomonials = () => {


    const { getTestimonialsData, isLoading, totalDataCount } = useSelector((state) => state.WebsiteSlice);

    const dispatch = useDispatch();

    useEffect(() => {
        console.log("testimonials.....");

        dispatch(getTestimonials());
    }, []);




    // const testimonialData = [
    //     {
    //         name: 'maha',
    //         location: 'chennai',
    //         img: require('../asset/images/Ellipse 2.png'),
    //         content: 'Centum App simplified learning, making it enjoyable and flexible for gaining valuable skills'
    //     },
    //     {
    //         name: 'maha',
    //         location: 'chennai',
    //         img: require('../asset/images/Ellipse 3.png'),
    //         content: 'Centum App simplified learning, making it enjoyable and flexible for gaining valuable skills'
    //     },
    //     {
    //         name: 'maha',
    //         location: 'chennai',
    //         img: require('../asset/images/Ellipse 4.png'),
    //         content: 'Centum App simplified learning, making it enjoyable and flexible for gaining valuable skills'
    //     },
    //     {
    //         name: 'maha',
    //         location: 'chennai',
    //         img: require('../asset/images/Ellipse 5.png'),
    //         content: 'Centum App simplified learning, making it enjoyable and flexible for gaining valuable skills'
    //     },
    //     {
    //         name: 'maha',
    //         location: 'chennai',
    //         img: require('../asset/images/Ellipse 2.png'),
    //         content: 'Centum App simplified learning, making it enjoyable and flexible for gaining valuable skills'
    //     },
    //     {
    //         name: 'maha',
    //         location: 'chennai',
    //         img: require('../asset/images/Ellipse 3.png'),
    //         content: 'Centum App simplified learning, making it enjoyable and flexible for gaining valuable skills'
    //     },
    //     {
    //         name: 'maha',
    //         location: 'chennai',
    //         img: require('../asset/images/Ellipse 4.png'),
    //         content: 'Centum App simplified learning, making it enjoyable and flexible for gaining valuable skills'
    //     },
    //     {
    //         name: 'maha',
    //         location: 'chennai',
    //         img: require('../asset/images/Ellipse 5.png'),
    //         content: 'Centum App simplified learning, making it enjoyable and flexible for gaining valuable skills'
    //     },
    //     {
    //         name: 'maha',
    //         location: 'chennai',
    //         img: require('../asset/images/Ellipse 2.png'),
    //         content: 'Centum App simplified learning, making it enjoyable and flexible for gaining valuable skills'
    //     },
    //     {
    //         name: 'maha',
    //         location: 'chennai',
    //         img: require('../asset/images/Ellipse 3.png'),
    //         content: 'Centum App simplified learning, making it enjoyable and flexible for gaining valuable skills'
    //     },
    //     {
    //         name: 'maha',
    //         location: 'chennai',
    //         img: require('../asset/images/Ellipse 4.png'),
    //         content: 'Centum App simplified learning, making it enjoyable and flexible for gaining valuable skills'
    //     },
    //     {
    //         name: 'maha',
    //         location: 'chennai',
    //         img: require('../asset/images/Ellipse 5.png'),
    //         content: 'Centum App simplified learning, making it enjoyable and flexible for gaining valuable skills'
    //     },
    //     {
    //         name: 'maha',
    //         location: 'chennai',
    //         img: require('../asset/images/Ellipse 2.png'),
    //         content: 'Centum App simplified learning, making it enjoyable and flexible for gaining valuable skills'
    //     },
    //     {
    //         name: 'maha',
    //         location: 'chennai',
    //         img: require('../asset/images/Ellipse 3.png'),
    //         content: 'Centum App simplified learning, making it enjoyable and flexible for gaining valuable skills'
    //     },
    //     {
    //         name: 'maha',
    //         location: 'chennai',
    //         img: require('../asset/images/Ellipse 4.png'),
    //         content: 'Centum App simplified learning, making it enjoyable and flexible for gaining valuable skills'
    //     },
    //     {
    //         name: 'maha',
    //         location: 'chennai',
    //         img: require('../asset/images/Ellipse 5.png'),
    //         content: 'Centum App simplified learning, making it enjoyable and flexible for gaining valuable skills'
    //     }
    // ]
    var settings = {
        // className: "center",
        centerMode: true,
        infinite: true,
        // centerPadding: "60px",
        slidesToShow: 5,
        // speed: 500
    }

    return (
        <>
            <div className='Testimonials'>
                <div className='Testimonials-header'>
                    <p>Testimonials from Our Students</p>
                </div>
                <div className='colanImage'>
                    <img src={colanImage} alt='keyFeaturesImage ' className="colanImage-img" />
                </div>
                {/* <div className='testimonials-para'>
                    <p>Centum App simplified learning, making it enjoyable and flexible for gaining valuable skills</p>
                </div> */}

                <div className='testimonials-cards'>

                    {/* <Slider {...settings}>
                        {
                            testimonialData.map((e, i) => {
                                return <div className="testimonial-slide" id={`testimonial-slide-${i + 1}`}>
                                    <div className='testimonial-body'>
                                        <div className='testimonial-single-card'>
                                            <div className='testimonial-content'>
                                                <p className='testimonials-para'>{e.content}</p>
                                            </div>
                                            <img src={e.img} alt='dollAnimation' className="testmonial-img" />
                                            <div className='testimonial-content'>
                                                <p className='name'>Shobana Mahi</p>
                                                <p className='location'>Chennai</p>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            })
                        }
                    </Slider> */}



<Slider {...settings}>
            {getTestimonialsData.map((e, i) => (
                <div key={i} className="testimonial-slide" id={`testimonial-slide-${i + 1}`}>
                    <div className='testimonial-body'>
                        <div className='testimonial-single-card'>
                            <div className='testimonial-content'>
                                <p className='testimonials-para'>{e.content}</p>
                            </div>
                            <img src={e.profileImageUrl} alt='profileImage' className="testmonial-img" />
                            <div className='testimonial-content'>
                                <p className='name'>{e.name}</p>
                                <p className='location'>{e.location}</p>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </Slider>
                </div>



            </div>


        </>
    );
}
export default Testomonials;