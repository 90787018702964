import React, { useState, useEffect, useRef } from 'react';
import '../components/style/termscondition-header.scss'
import termConditionImg from '../asset/images/terms-and-conditions 1.png';



const TermsCondition_Header = () => {

    return (
        <>


            <div className='terms-condition-container'>
                <div className='wrapper-container'>
                <div className='terms-condition-header'>
                    <p>Home  | <span>Terms & conditions</span> </p>
                    <h2>Terms & conditions</h2>

                </div>
                <div className='terms-sub-header'>
                    <img src={termConditionImg} alt='Keystar' className="" />

             </div>
                </div>
            </div>



        </>
    );
}
export default TermsCondition_Header;