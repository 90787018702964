import React, { useEffect, useState, useRef } from "react";
// import "./style/Institutiuon_Associate.scss";
import associateImg1 from '../asset/images/associateimage1.png';
import associateImg2 from '../asset/images/associateimage2.png';
import './style/institution-associate.scss'


const Institutiuon_Associate = () => {
   


    const associateUs = [
        {
          imgSrc: associateImg1,
          alt: 'logo-community',
          heading: 'College Virtual Tutoring Solutions',
          text: 'Inorder to assist the students to get into Government jobs like IAS and be an integral part of nation building and also crate a great alumni pool.',
        
        },
        {
          imgSrc: associateImg2,
          alt: 'logo-testmi',
          heading: 'Practices for NAAC Accreditation',
          text: 'These projects can be included under the Innovative practices for NAAC Accreditation',
       
        },
      ];

  return (
    <>

<div className="associate-program">
<div className="wrapper-container">

      <div className="associate-program-header">
        <p>Why Colleges should associate with us?</p>
      </div>
      <div className='associate-cardview'>
        {associateUs.map((card, index) => (
          <div key={index} className="associate-card-content">
            <img src={card.imgSrc} alt={card.alt} />
            <div className="associate-main-para">
            <p className='associate-cardheading'>{card.heading}</p>
            <p className='assocoiate-cardtext'>{card.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
    
</div>

    </>
  );
};
export default Institutiuon_Associate;
