// Header.js

import React, { useState } from 'react';
import Logo from '../asset/images/Group.png';
import './style/responsiveheadertest.scss';
import { MenuOutlined } from '@ant-design/icons';
import Cancel from '../asset/images/Cancelsymbol.png';
import { useLocation } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [sideDrawerWidth, setSideDrawerWidth] = useState('0');
  const handleLoginClick = () => {
    window.location.href = 'https://app.centum.academy/login';
  };

  const handleRegisterClick = () => {
    window.location.href = 'https://app.centum.academy/signUp';
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setSideDrawerWidth(isMenuOpen ? '0' : '200px');
  };

  const items = [
    { label: 'Home', key: 'Home', path: '/' },
    { label: 'Community', key: 'community', path: '/community' },
    { label: 'TestMI', key: 'testmi', path: '/testMi' },
    { label: 'CoachMI', key: 'coachmi', path: '/coachMi' },
    { label: 'Exams', key: 'exams', path: '/exams' },
    { label: 'About us', key: 'aboutus', path: '/aboutus' },

  ];
  const menuItem = [
    {
      label: (
        <a target="_self" rel="noopener noreferrer" href="/institutionPartner">
          Institution partner program
        </a>
      ), key: 'institutionprogram', path: '/institutionPartner'
    },
    {
      label: (<a target="_self" rel="noopener noreferrer" href="/schoolsHub">
        Hub Schools
      </a>), key: 'hubschools', path: '/schoolsHub'
    },
    {
      label: (<a target="_self" rel="noopener noreferrer" href="/franchisePage">
      Franchise
      </a>), key: 'franchisePage', path: '/franchisePage'
    }
  ]

  const dropchange = (e) => {
    console.log(e)
  }

  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <>
      <div className="header">
        <div className='hamburger-logo'>
          <div className="hamburger" onClick={toggleMenu}>
            <MenuOutlined />
          </div>
          <div className="logo">
            <img src={Logo} alt='logo' className="logo-img" />
          </div>
        </div>
        <div className={`menu ${isMenuOpen ? 'open' : ''}`}>
          <div className="menu-items">
            <div className="logo">
              <div className="cancel-logo-header">
                <img src={Cancel} alt="cancel" className='cancel-icon' onClick={toggleMenu} />
                <img src={Logo} alt='logo' className="logo-img" />
              </div>
              <div className="footer-sepline"></div>
            </div>

            {items.map((item) => (
              <a key={item.key} href={item.path || '#'}>
                <span className={currentPath === item.path ? 'selected' : ''}>{item.label}</span>
              </a>

            ))}
            <Dropdown className='dropdown-navbar'
              menu={{ items: menuItem, }}
              trigger={['click']}
            >
              <a onClick={dropchange}>
                <Space>
                  Services
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          </div>
        </div>
        <div className='cta-group'>
          <button className="cta-outline" onClick={handleLoginClick}>Log In</button>
          <button className="cta" onClick={handleRegisterClick}>Register</button>
        </div>
      </div>
    </>
  );
};

export default Header;






