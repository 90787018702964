import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import India from './../asset/images/india.png';
import Upsc from './../asset/images/upsc-logo-new.png';
import Bank from './../asset/images/bank.png';
import Asc from './../asset/images/ace.png';
import Ssc from './../asset/images/ssc.png';
import Railway from './../asset/images/railway.png';
import Neet from './../asset/images/neer.png';
import Whatsapp from './../asset/images/whatsapp.png';
import Youtube from './../asset/images/yt.png';
import Telegram from './../asset/images/telegram.png';
import Instagram from './../asset/images/ista.png';
import Follow from './../asset/images/Group (1).png';
import Star from './../asset/images/star.png';
import Heart from './../asset/images/hot.png';
import './style/centumapp-community.scss';
import { getCommunity } from './reducers/WebsiteSlice';


// <div className='centum-image'  > <img src={Follow} alt='logo' className="logo-img" /> </div>
// <div className='centum-image_1'  > <img src={Star} alt='logo' className="logo-img" /> </div>
{/* <div className='centum-image_last'  > <img src={Hot} alt='logo' className="logo-img" /></div> */ }


const Centumapp_community = () => {
    const [isVisible, setIsVisible] = useState(true);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };
    // const [isHovered, setIsHovered] = useState(false);

    const buttonStyle = {
        backgroundColor: '#FFFFFF',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: '',
        // padding: '10px', // Adjust as needed
        gap: '10px',
        border: 'none'
    };

    const imgStyle = {
        marginRight: '5px', // Adjust as needed
        verticalAlign: 'middle',
    };
    const { getCommunityData } = useSelector((state) => state.WebsiteSlice);

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(getCommunity());
        console.log("hpttttttttts...", getCommunityData);

    }, []);

    console.log("hpttttttttts...", getCommunityData);
    const [selectedCategoryId, setSelectedCategoryId] = useState( null);
    useEffect(() => {
        if (getCommunityData.length > 0) {
            setSelectedCategoryId(getCommunityData[0].id);
        }
    }, [getCommunityData]);
    const handleClick = (id) => {
        setSelectedCategoryId(id);
        console.log(id,'ffffffffff');
    };


    return (
        <>
            <div id="centumapp_community" className="startlearning">
            <div className="wrapper-container">

                <div className='centumapp-image'>
                    <img src={Follow} alt='logo' className="logo-img" />

                </div>

                <div className='centumapp-image_1'>

                    <img src={Star} alt='logo' className="logo-img" />

                </div>

                <div className='centum-imageapp_last'>

                    <img src={Heart} alt='logo' className="logo-img" />

                </div>



                <div className="centum-community-container">
                    <div className="whole">

                        <div className="whole_1">
                            <div className="head1">
                                <p>Join Our CentumApp Community </p>
                            </div>
                            <div className='head2'>
                                <p>Points of community</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="whole2">
                    <div className="highlight_card">
                        <div className="group11">
                        {getCommunityData.slice(0, 2).map((category, index) => (
                            <div key={index} className="high_subgroup">
                                <button style={buttonStyle} onClick={() => handleClick(category.id)}>
                                    <img src={category.logoUrl} alt='logo' /> {category.name}</button>
                            </div>
                         ))}
                        </div>
                     
                    </div>


                    <div className="card1">
                        <div className="group1">
                        {getCommunityData.slice(2).map((category, index) => (
                            <div className="subgroup">
                                <button style={buttonStyle} onClick={() => handleClick(category.id)}>
                                    <img src={category.logoUrl} alt='logo' /> {category.name}
                                    </button>
                            </div>
                              ))}
                        </div>                      
                    </div>
                    <div className='details_container_community'>
                    {selectedCategoryId && getCommunityData.map((category) => {
                    if (category.id === selectedCategoryId) {
                        return (
                            <div className="final_card" key={category.id}>
                                {category.socialMedia.map((social) => (
                                    <a href={social.socialMediaLink} key={social.name}>
                                        <div className="final_card_1">
                                            <img src={social.socialMediaUrl} alt={social.name} className="logo-img" />
                                            {social.name}
                                        </div>
                                    </a>
                                ))}
                            </div>
                        )
                    }
                    return null;
                })}

                    </div>
                </div>

            </div>
            </div>
        </>
    )
}
export default Centumapp_community
