import React, { useEffect, useState, useRef } from "react";
import "./style/coachMi-banner.scss";
import rocket from "../asset/images/rocketweb.png";
import rocketmobile from "../asset/images/Frame rocket.svg";
import arrow from "../asset/images/testmibannerarrow.png";
import book from "../asset/images/testmibannerbook.png";
import bubble from "../asset/images/tetmibubble.png";
import youtubeCoachmi from "../asset/images/Frame youtube.svg";
import messageCoachmi from "../asset/images/Frame message.svg";
import communityStaff from "../asset/images/WhatsApp Image .png";
const Community_Banner = ({exploreCommunityRoute}) => {
  const [textIndex, setTextIndex] = useState(0);

  const textArray = [
    "Join Our <span style='color:#2D81F7;'>Community</span> to Unlock a World of Learning",
    "Become a part of our <span style='color: #2D81F7;'>Community</span> and enjoy exclusive perks",
    "Enlist in our <span style='color: #2D81F7;'>Community</span> to receive the following perks",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % textArray.length);
    }, 700);

    return () => clearInterval(interval);
  }, []);

  const communityExploreClick =()=>{
    if(exploreCommunityRoute){
      exploreCommunityRoute();
    }

  }

  return (
    <>
      <div className="banner-headercontain-section bg">
        <div className="wrapper-container">
        <div className="banner-container-section">
          <div
            className="banner-text-section community-banner-text"
            dangerouslySetInnerHTML={{ __html: textArray[textIndex] }}
          />

          <div className="banner-btn-section">
            <button className="ban-btn-clr-section" onClick={communityExploreClick}>Explore </button>
            <img
              src={rocketmobile}
              alt="rocket"
              className="rocketMobile-image-section paper-rocket-community"
            />
          </div>
        </div>

        <div className="banner-image-section">
          <img src={rocket} alt="rocket" className="rocketWeb-image rocket-img" />
          <img src={arrow} alt="arrow" className="arrow-img-section" />
        </div>

        <div className="banner-right-section">
          <img src={communityStaff} alt="arrow" className="second-img-imgage community-staff-img" />
          <img src={bubble} alt="arrow" className="bubble-img-image bubble-community" />
          <img src={book} alt="arrow" className="grp-img-image grp-community" />
          <img src={youtubeCoachmi} alt="arrow" className="utube-img-image youtube-community" />
          <img src={messageCoachmi} alt="arrow" className="message-img-image message-community" />
        </div>
      </div>
      </div>
    </>
  );
};
export default Community_Banner;
