import React from 'react';
import '../components/style/aboutus-members.scss';

import princeImage from '../asset/images/princeImg.png';
import image2 from '../asset/images/image2.png';
import image3 from '../asset/images/image3.png';
import image4 from '../asset/images/image4.png';
import image5 from '../asset/images/image5.png';
import image6 from '../asset/images/image6.png';
import image7 from '../asset/images/coachmiTeache.png';
import image8 from '../asset/images/image8.png';
import buestarAboutus from '../asset/images/aboutblueImage.png';
import greenstarAboutus from '../asset/images/aboutGreenStar.png';



const AboutMembers = () => {
    const members = [
        {
            id: 1,
            image: princeImage,
            name: 'Prince J',
            role: 'Project Manager (Exams)',
            
        },
        {
            id: 2,
            image: image2,
            name: 'Siva Prabu',
            role: 'Category Head - Operations',
        },
        {
            id: 3,
            image: image3,
            name: 'Ibrahim Nachiya',
            role: 'Category Head, NET',
        },
        {
            id: 4,
            image: image4,
            name: 'Buvankumar V',
            role: 'Category Associate - UPSC',
        },
        {
            id: 5,
            image: image5,
            name: 'Sudhir Kumar',
            role: 'Manager - Hindi verticals',
        },
        {
            id: 6,
            image: image7,
            name: 'Karthik S',
            role: 'Category Associate - SSC',
        },
        {
            id: 7,
            image: image6,
            name: 'Ramachandran S',
            role: 'Category Associate ',
            subrole:'(Institution Partner Program)',
           
        },
        {
            id: 8,
            image: image8,
            name: 'Radhika',
            role: 'SME & Content Creator',
        }
    ];

    return (
        <>
            <div className='aboutus-member-content'>
             <div className='wrapper-container'>
                    
                <div className="aboutus-member-header">
                    <h2>Crew Members of Centum</h2>
                    <p>Meet the Faces Behind Centum's Success - Our Exceptional Crew Members!</p>
                </div>
                <div className='aboutus-member-image'>

      <img src={buestarAboutus} alt='Keystar' className="aboutus-starImage-prince blink" style={{ animationDelay: '1s' }} />
      <img src={buestarAboutus} alt='Keystar' className="aboutus-starImage1 blink" style={{ animationDelay: '2s' }} />
      <img src={buestarAboutus} alt='Keystar' className="aboutus-starImage2 blink" style={{ animationDelay: '3s' }}/>
    




      </div>
                <div className="member-grid">


                    {members.map((member) => (
                        <div key={member.id} className="member-card">
                            <img src={member.image} alt={`Member ${member.id}`} />
                            <div className="member-info">
                                <h2>{member.name}</h2>
                                <p>{member.role}</p>
                                <p className='sub-role'>{member?.subrole}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='aboutus-bottom-image'>

<img src={greenstarAboutus} alt='Keystar' className="aboutus-starImage blink"  style={{ animationDelay: '4s' }}/>
<img src={buestarAboutus} alt='Keystar' className="aboutus-starImage3 blink" style={{ animationDelay: '5s' }} />
      <img src={buestarAboutus} alt='Keystar' className="aboutus-starImage4 blink" style={{ animationDelay: '6s' }}/>
      </div>
            </div>
            </div>
        </>
    );
}

export default AboutMembers;
