import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './style/keyfeature-landing.scss';
import Lanstar from '../asset/images/keyBorderBlue_image.png';
import Lanstarblue from '../asset/images/keystar.png';
import Circle from '../asset/images/circleleft.png';
import Circleright from '../asset/images/circleright.png';
import Testmi from '../asset/images/testmi.png';
import Coachmi from '../asset/images/video-recorder.png';
import Community from '../asset/images/users-01.png';
import Institution from '../asset/images/kbuild.png';
import Leader from '../asset/images/kleader.png';
import Exam from '../asset/images/Exam Analytics.png';
import { keyFeatures, getStatusFeatures } from '../components/reducers/WebsiteSlice';

const Keyfeature_landing = () => {
  const { keyFeaturesData, isLoading, totalCount } = useSelector((state) => state.WebsiteSlice);

  const dispatch = useDispatch();

  useEffect(() => {
    // console.log("getCurrentAffairs.....",currentAffairsData);

    dispatch(keyFeatures());
  }, []);
  // console.log("bhartahi",keyFeaturesData)
  const data = [
    {
      imgSrc: Testmi,
      altText: 'arrow',
      header: 'Testmi',
      content: 'Complete Test Solution'
    },
    {
      imgSrc: Coachmi,
      altText: 'arrow',
      header: 'Coachmi',
      content: 'Expert-Led Learning'
    },
    {
      imgSrc: Community,
      altText: 'arrow',
      header: 'Community',
      content: 'Join Aspiring Community'
    },
    {
      imgSrc: Institution,
      altText: 'arrow',
      header: 'Institution Login',
      content: 'Secure Institution Access'
    },
    {
      imgSrc: Leader,
      altText: 'arrow',
      header: 'Leader Board',
      content: 'Display'
    },
    {
      imgSrc: Exam,
      altText: 'arrow',
      header: 'Exam Analytics',
      content: 'Test Result Overview'
    }
  ];

  return (
    <>
      <div className="overall-key-lan font keycolor">
      <div className="wrapper-container">

        <div className="feature-landing-container gradient-background">
          <div className="left-feature-lan">

            <div className="left-stars">
              <div className="star-lan">
                <img src={Lanstar} alt='arrow' className="star blink" style={{ animationDelay: '1s' }} />
              </div>
              <div className="starblue-lan">
                <img src={Lanstarblue} alt='arrow' className="star blink" style={{ animationDelay: '2s' }} />
              </div>
              <div className="startwo-lan">
                <img src={Lanstar} alt='arrow' className="star blink" style={{ animationDelay: '3s' }} />
              </div>
            </div>



            <div className="circle-lan">
              <img src={Circle} alt='arrow' className="star" />
            </div>
          </div>
          <div className="middle-feature-lan ">
            <div className="hole-feature-lan">
              <div className="key-feature-lan">
                Key Features
              </div>
              <div className="supercharge-lan">
                Supercharge your prep with our cutting-edge suite.
              </div>
            </div>
            <div className="hole-icon-lan">
              <div className="first-icon">
                {keyFeaturesData.map((item, index) => (
                  <div className="testmi-lan" key={index}>
                    <div className="test-img">
                      <img src={item.iconUrl} alt={item.imgSrc} className="star" />
                    </div>
                    <div className="test-label">
                      <div className="test-header">{item.title}</div>
                      <div className="test-content">{item.subTitle}</div>
                    </div>
                  </div>
                ))}
              </div>


            </div>
          </div>
          <div className="right-feature-lan">
            <div className="circle-lan">
              <img src={Circleright} alt='arrow' className="star " />
            </div>
            <div className="right-stars">
              <div className="right-star-lan">
                <img src={Lanstar} alt='arrow' className="star blink" style={{ animationDelay: '4s' }} />
              </div>
              <div className="right-bluestar-lan">
                <img src={Lanstarblue} alt='arrow' className="star blink" style={{ animationDelay: '5s' }} />
              </div>
              <div className="right-twostar-lan">
                <img src={Lanstar} alt='arrow' className="star blink" style={{ animationDelay: '6s' }} />
              </div>
            </div>


          </div>


        </div>
      </div>
</div>


    </>
  )
}
export default Keyfeature_landing;