import React, { useState, useEffect } from 'react';
import lines from './../asset/images/636d94faa814285d196944ce_Squiggle_Leave_1.svg.png';
import linelogo2 from './../asset/images/line-logo2.png';
import './style/testavail.scss';

const TestAvail = () => {
    const testData = [
        {
            "text": "No of active TestMI user",
            "num": 7000
        },
        {
            "text": "Total questions available",
            "num": 20000
        },
        {
            "text": "Topic wise test available",
            "num": 500
        }
    ];

    const [count, setCount] = useState(0);

    useEffect(() => {
        let maxCount = 0;
        testData.forEach(item => {
            if (item.num > maxCount) {
                maxCount = item.num;
            }
        });

        const interval = setInterval(() => {
            setCount(prevCount => {
                if (prevCount < maxCount) {
                    return prevCount + 1;
                } else {
                    clearInterval(interval);
                    return prevCount;
                }
            });
        }, 10);

        return () => clearInterval(interval);
    }, []);

    const formatCount = (count) => {
        if (count >= 1000000000) {
            return `${Math.floor(count / 1000000000)}B`;
        } else if (count >= 1000000) {
            return `${Math.floor(count / 1000000)}M`;
        } else if (count >= 1000) {
            return `${Math.floor(count / 1000)}K`;
        } else {
            return count;
        }
    };

    return (
        <div className="overall">
        <div className="wrapper-container">

            <div className="container">
                <div className="symbol1">
                    <img src={lines} alt='icon' className="lines-logo" />
                </div>
                <div className="symbol2">
                    <img src={linelogo2} alt='icon' className="lines-logo2" />
                </div>

                <div className="firsthalf">
                    <div className="para">
                        <p>Many of our users successfully located or attempted questions that closely resembled the ones featured in the app's test materials.</p>
                    </div>
                </div>

                <div className="secondhalf">
                    {testData.map((item, index) => (
                        <div className="updn" key={index}>
                            <div className='text'><p>{item.text}</p></div>
                            <div className='num'><p style={{ color: 'green' }}>{formatCount(count)}+</p></div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
        </div>
    );
};

export default TestAvail;
