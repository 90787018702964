import React, { useState } from 'react';
import { Card } from 'antd';
import whitestar from '../asset/images/whitestar.png';
import tnpscImage from '../asset/images/tnpscImage.png';
import telegramImage from '../asset/images/telegramImag.png';
import youtubeImage from '../asset/images/YouTube.png';
import instagramImage from '../asset/images/Instagram.png';
import whatsappImage from '../asset/images/WhatsApp.png';
import sscImage from '../asset/images/sscImage.png';
import upscImage from '../asset/images/upscImage.png';
import neetImage from '../asset/images/neetImage.png';
import mobileImage from '../asset/images/mobileImage.png';

import { WhatsAppOutlined, YoutubeOutlined, InstagramOutlined, MessageOutlined } from '@ant-design/icons';

const { Meta } = Card;
const communityData = [
    {
        name: 'TNPSC', image: tnpscImage, socialMedia: [
            { image: telegramImage, text: 'Telegram' },
            { image: whatsappImage, text: 'Whatsapp' },
            { image: youtubeImage, text: 'Youtube' },
            { image: instagramImage, text: 'Instagram' },
        ]
    },
    {
        name: 'SSC Tamil', image: sscImage, socialMedia: [
            { image: telegramImage, text: 'Telegram' },
            { image: whatsappImage, text: 'Whatsapp' },
            { image: youtubeImage, text: 'Youtube' },
            { image: instagramImage, text: 'Instagram' },
        ]
    },
    {
        name: 'SSC Tamil', image: sscImage, socialMedia: [
            { image: telegramImage, text: 'Telegram' },
            { image: whatsappImage, text: 'Whatsapp' },
            { image: youtubeImage, text: 'Youtube' },
            { image: instagramImage, text: 'Instagram' },
        ]
    },
    {
        name: 'SSC English', image: sscImage, socialMedia: [
            { image: telegramImage, text: 'Telegram' },
            { image: whatsappImage, text: 'Whatsapp' },
            { image: youtubeImage, text: 'Youtube' },
            { image: instagramImage, text: 'Instagram' },
        ]
    },
    {
        name: 'UPSC', image: upscImage, socialMedia: [
            { image: telegramImage, text: 'Telegram' },
            { image: whatsappImage, text: 'Whatsapp' },
            { image: youtubeImage, text: 'Youtube' },
            { image: instagramImage, text: 'Instagram' },
        ]
    },
    {
        name: 'NEET', image: neetImage, socialMedia: [
            { image: telegramImage, text: 'Telegram' },
            { image: whatsappImage, text: 'Whatsapp' },
            { image: youtubeImage, text: 'Youtube' },
            { image: instagramImage, text: 'Instagram' },
        ]
    },
    // Add more community data objects as needed
];

const Community = () => {

    return (
        <>
            <div className='community-block'>
                {/* <div className='community-imageblock'> */}
                <div className='community-container'>
                    <div className='community'>
                        <div className='community-textArea'>
                            <div className='community-header'>
                                <div className='header-image'>
                                    <h2>Join Our CentumApp Community</h2>
                                    <img src={whitestar} alt='whitestar' className="whitestar-image" />
                                </div>
                                <p>Points of community</p>
                            </div>
                        </div>
                        <div className='community-list'>
                            {communityData.map((community, index) => (
                                <div key={index} className='community-card'>
                                    <div className='card-content'>
                                        <div className='card-header'>
                                            <img src={community.image} alt={`${community.name} Image`} className="tnpscImage-image" />
                                            <h2>{community.name}</h2>
                                        </div>

                                        <div className='line'></div>
                                        <div className='social-media-cards'>
                                            <div className='row1'>
                                                {community.socialMedia.slice(0, 2).map((media, i) => (
                                                    <div key={i} className='social-media-content'>
                                                        <img src={media.image} alt={`${media.name} Image`} className="social-media-image" />
                                                        <p>{media.text}</p>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="row2">
                                                {community.socialMedia.slice(2).map((media, i) => (
                                                    <div key={i} className='social-media-content'>
                                                        <img src={media.image} alt={`${media.name} Image`} className="social-media-image" />
                                                        <p>{media.text}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>
                        <div className='footer-image'>
                            <img src={whitestar} alt='whitestar' className="whitestar-image" />
                        </div>
                    </div>
                    <div className='centumapp-image'>
                        <img src={mobileImage} alt='mobileImage' className="mobileImage-image" />
                    </div>
                </div>

                {/* <img src={mobileImage} alt='mobileImage' className="mobileImage-image" /> */}



                {/* <div className='centumapp-image'>

                <img src={mobileImage} alt='mobileImage' className="mobileImage-image" />
              </div> */}

            </div>
            {/* <div className='centumapp-image'>

                <img src={mobileImage} alt='mobileImage' className="mobileImage-image" />
                </div> */}
        </>
    );
}
export default Community;