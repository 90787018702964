import React, { useState, useEffect } from 'react';
import { Layout, Breadcrumb, Flex, Button } from 'antd';
import { Input } from 'antd';
import { Menu, theme } from 'antd';
// import 'antd/dist/antd.css'; // Import the Ant Design styles
import uccImage from '../asset/images/uccImage.png';
import tnpscImage from '../asset/images/TNPSCFrame .png';
import sscImage from '../asset/images/sscFrame .png';
import { Carousel, Card } from 'antd';
import Slider from "react-slick";
import registerFrame from '../asset/images/registerFrame .png';
import Singlestar from '../asset/images/Star 12.png';
import RocketImage from '../asset/images/rocketImage.png';
import { getproducts } from '../components/reducers/WebsiteSlice';
import { useDispatch, useSelector } from 'react-redux';

const { Meta } = Card;



const Product = () => {

    const { getproductsData, isLoading, totalDataCount } = useSelector((state) => state.WebsiteSlice);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getproducts());
    }, []);

   








    var settings = {

        
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        // responsive: [
        //     {
        //         breakpoint: 768,
        //         settings: {
        //             slidesToShow: 3,
        //             slidesToScroll: 3,
        //         },
        //     },
        //     {
        //         breakpoint: 992,
        //         settings: {
        //             slidesToShow: 3,
        //             slidesToScroll: 3,
        //         },
        //     },
        //     // Add more breakpoints as needed
        // ],
    };

    return (
        <>
            <div className='sub-product'>
                <h1>Our Top Products</h1>
                <p>Check out our best products to boost your knowledge!</p>
            </div>
            
            <div className='product-overall'>
      <div className='product-cards'>
        <Slider {...settings}>
          {getproductsData.map((product, index) => (
            <div className="product" key={index}>
              <Card
                hoverable
                style={{
                  width: 240,
                }}
                // cover={<img alt="example" src={product.productImageUrl} />}
                cover={<img alt="example" src={uccImage} />}
              >
                <Meta title={product.title} />
                <Button type='default' className='explore-button'>Explore</Button>
              </Card>
            </div>
          ))}
        </Slider>
      </div>
    </div>
             {/* <div className='product-overall'>
              <div className='product-cards'>
    <Slider {...settings}>
        <div className="product">
            <Card
                hoverable
                style={{
                    width: 240,
                }}
                cover={<img alt="example" src={uccImage} />}>
                <Meta title="Centum's UGC - NET, JRF & SET GATE" />
                <Button type='default' className='explore-button'>Explore</Button>
            </Card>
        </div>
        <div className="product">
            <Card
                hoverable
                style={{
                    width: 240,
                }}
                cover={<img alt="example" src={sscImage} />}>
                <Meta title="SSC GD Constable Mock Test 2024" />
                <Button type='default' className='explore-button'>Explore</Button>
            </Card>
        </div>
        <div className="product">
            <Card
                hoverable
                style={{
                    width: 240,
                }}
                cover={<img alt="example" src={tnpscImage} />}>
                <Meta title="TNPSC Exam 2024 (Tamil)" />
                <Button type='default' className='explore-button'>Explore</Button>
            </Card>
        </div>
    </Slider>
</div>
</div>  */}

            <div className='register-frame'>
                <div className='register-para'>
                    <span className='rocket-regimage'><img src={RocketImage} alt='registerFrame' className="rocket-img" /></span>

                    <img src={Singlestar} alt='registerFrame' className="Singlestar-img1" />
                    <p>
                        Empower your learning journey. Register today and unlock limitless education !
                    </p>
                    <img src={Singlestar} alt='registerFrame' className="under-singlestar-img" />

                    <img src={Singlestar} alt='registerFrame ' className="Singlestar-img2" />
                    <Button>Register</Button>

                </div>

            </div>

        </>
    )

}

export default Product;



