import React, { useState } from 'react';
import boyImage from '../asset/images/Frame 1261157766.png';
import vector from '../asset/images/Vector.png';
import knowledgeImage from '../asset/images/knowledge_8596157 1.png';
import computerImage from '../asset/images/computer_2072892 1.png';
import groupCenImage from '../asset/images/centumgroup.png';
import languagesImage from '../asset/images/languages_2169688 1.png';
import greenStar from '../asset/images/greenstar.png';
import yellowStar from '../asset/images/yellowstar.png';
import brownStar from '../asset/images/brownstar.png'
import centumImage from '../asset/images/centumFrameIMage.png'
import { Card } from 'antd';




const { Meta } = Card;
const CentumPage = () => {

    return (
        <>
            <div className='centum-learning'>
                <div className='centum-textArea'>
                    <div className='centum-content'>
                        <h2 className='centum-header'>Why CentumApp?</h2>
                        <p className='centum-paragrah'>Get access to all our materials and resources by joining the community of thousands of aspirants and get yourself imbibed in the process of learning among peers!</p>
                    </div>
                    <div>
                        <img src={boyImage} alt='boyImage' className="boyImage-image" />

                    </div>
                </div>

                <div className='centum-image'>
                    <div className='centum-imagecard'>
                        <span className='star-image'> <img src={greenStar} alt='boyImage' className="green-star" /> </span>
                        <div className='card-image card-1'>
                            <Card
                                hoverable
                                cover={<img className='images-tab' alt="example" src={knowledgeImage} />}>
                                <Meta title="Learn from the best" description="Learn from the masters of the subject, in the most engaging yet simplified ways." />
                            </Card>
                        </div>
                        <div className='card-image card-2'>
                            <Card
                                hoverable
                                cover={<img className='images-tab' alt="example" src={groupCenImage} />}>
                                <Meta title="Detailed Score Analysis" description="Get a detailed breakdown of your strength & weakness and discover insight to improve your score." />
                            </Card>
                        </div>
                        <span className='star-image-1'> <img src={yellowStar} alt='boyImage' className="yellow-star" /> </span>
                    </div>
                    <div className='centumcard-image'>
                    <span className='star-image-2'> <img src={brownStar} alt='brown-star' className="brown-star" /> </span>
                         <div className='card-image card-3'>
                            <Card hoverable
                             cover={<img className='images-tab' alt="example" src={computerImage} />}>
                                <Meta title="Live Exam Simulation" description="Master exams with precision through our Live Exam Simulation – your key to realistic, high-stakes preparation!" />
                            </Card>
                        </div>
                        <div className='card-image card-4'>
                            <Card 
                                hoverable 
                                
                                cover={<img className='images-tab' alt="example" src={languagesImage} />}>
                                <Meta title="Multilingual" description="Enhance your expertise effortlessly across multiple languages for understanding and accelerated learning." />
                            </Card>
                        </div>
                    </div>

                </div>
            </div>

        </>
    );
}
export default CentumPage;