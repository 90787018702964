import React, { useState } from 'react';
import './style/testmi-features.scss';
import leftIcon from '.././asset/images/tesmibannertopleft.png'
import rightIcon from '.././asset/images/tesmibannertopright.png'
import rectangle from '.././asset/images/Rectangle 3465386.png'
import testmifeatures from '.././asset/images/testmifeatures.png'

const Testmi_Features = () => {

    return (
        <>
        <div className='testmi-features'>
        <div className='wrapper-container'>

            <div className='testmi-features1'>
                <img className='leftIcon' src={rightIcon} alt='icon'/>
                <div className='testmi-features-head'>Trust <span style={{color:'#2D81F7'}}>Testmi</span> to guide you towards achieving your academic goals efficiently and effectively.</div>
                <img className='rightIcon' src={leftIcon} alt='icon'/>
                <div className='feature-points'>
                        <div className='testmi-features-text'><img className='rectangleIcon' src={rectangle} alt='icon'/>Testmi provides a user-friendly interface</div>
                        <div className='testmi-features-text'><img className='rectangleIcon' src={rectangle} alt='icon'/>Detailed performance analytics</div>
                        <div className='testmi-features-text'><img className='rectangleIcon' src={rectangle} alt='icon'/>Gives personalized feedback</div>
                </div>
            </div>
            <div className='testmi-features2'>
            <img src={testmifeatures} alt='icon'/>
            </div>
</div>

        </div>
         
        </>
    )
}
export default Testmi_Features;