import React, { useState } from 'react';
import './style/ourcommunity-pricing.scss';
import Heart_community from './../asset/images/heart-community.png';
import Follow_community from './../asset/images/follow-community.png';


const Ourcommunity_pricing = () => {

    return (
        <>
            <div className='ourCommunity-pricing-container'>
           

                <div className='overall-container-pricing' >
                <div className='wrapper-container'>
                    <div className='connect-text'>
                        <div className='connect-text-connect'>
                            <div className='heart-icon-community'>
                                <img src={Heart_community} alt='logo' className="logo-img" />
                            </div>
                            <div className='connect-text-easy'>
                                Connect Easily with Our Community
                            </div>
                            <div>
                                <div className='follow-icon-community'>
                                    <img src={Follow_community} alt='logo' className="logo-img" />
                                </div>
                                <div className='expand-text'>
                                    Expand Your Network, Join the Centum Community Today
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="price-container">
                        <div className="price-card">
                            <div className="head-desc-price">
                                <div className=" green price-heading">Starter Connect</div>
                                <div className="price-description">Unlock meaningful connections with ease. Elevate your community experience affordably.</div>
                                <div className="price-price-container">
                                    <div className="price-price">INR 29</div>
                                    <div className="price-month">/ 3 months</div>
                                </div>
                            </div>
                            <div className="price-btn-get-started">Get Started</div>
                        </div>

                        <div className="price-card">
                            <div className="head-desc-price">
                                <div className=" blue price-heading">Thriving Together</div>
                                <div className="price-description">Designed for those craving more, this plan unleashes exclusive features, ensuring you thrive alongside your peers</div>
                                <div className="price-price-container">
                                    <div className="price-price">INR 49</div>
                                    <div className="price-month">/ 6 months</div>
                                </div>
                            </div>
                            <div className="price-btn-get-started">Get Started</div>
                        </div>
                        <div className="price-card">
                            <div className="head-desc-price">
                                <div className="violet price-heading">Premier Alliance</div>
                                <div className="price-description">Experience VIP perks, elevate your community standing, and savor the exclusivity you deserve.</div>
                                <div className="price-price-container">
                                    <div className="price-price">INR 99</div>
                                    <div className="price-month">/ 12 months</div>
                                </div>
                            </div>
                            <div className="price-btn-get-started">
                                <p>Get Started</p>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
            </div>
        </>
    )
}
export default Ourcommunity_pricing;