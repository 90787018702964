import React, { useState } from 'react';
// import './style/emppower-landing.scss';
import './style/institution-govermentExam.scss'
import star from './../asset/images/Star 12.png';
import rocket from './../asset/images/rocketImage.png';
import governmentImage1 from '../asset/images/govermnetImage1.png';
import governmentImage2 from '../asset/images/governmentImage2.png';
import governmentImage3 from '../asset/images/governmentImg3.png';
import governmentImage from '../asset/images/governmentImg.png';
import governmentImage4 from '../asset/images/governmentImg4.png'






const Institutiuon_Government = ({institutionContactNowClick}) => {

    const handleInstitutionClick = () => {
        if (institutionContactNowClick) {
          institutionContactNowClick();
        }
      };

    return (
        <>  
                <div className="container-government">
                <div className="wrapper-container">

                <img src={governmentImage4} alt='governmentImage' className='government-halfstar-Image'/>

                    <div className="government-content-btn">
                        <p className="government-content-para">
                        Partner with CentumAPP for Government Exam Prep!
                        </p>
                        <div className="government-button">
                         <a className="government-btn" onClick={handleInstitutionClick} >Contact Now </a>
                        </div>
                    </div>
                <img src={governmentImage2} alt='governmentImage' className='government-star-Image blink'/>
                <img src={governmentImage2} alt='governmentImage' className='government-star2-Image blink'/>

                <img src={governmentImage3} alt='governmentImage' className='government-dots-Image'/>
                  
                    <div className='second-governmentImage'>
                <img src={governmentImage} alt='governmentImage' className='government-Image'/>

                <img src={governmentImage1} alt='governmentmImage' className='government-mImage'/>

                </div>
                </div>
               </div>
        </>
    )
}
export default Institutiuon_Government;