import React, { useRef,useState,useEffect } from 'react';

import India from './../asset/images/india.png';
import Upsc from './../asset/images/upsc-logo-new.png';
import Bank from './../asset/images/bank.png';
import Asc from './../asset/images/ace.png';
import Ssc from './../asset/images/ssc.png';
import Railway from './../asset/images/railway.png';
import Neet from './../asset/images/neer.png';
import pen from './../asset/images/pen.png';
import notes from './../asset/images/notes.png';
import save10 from './../asset/images/save10.png';
import save15 from './../asset/images/save15.png';
import save20 from './../asset/images/save20.png';
import purchasebtn from './../asset/images/purchasebtn.png';
import purchase from './../asset/images/purchase2.png';
import star from './../asset/images/Group 1000001837.png'
// import notes from './../asset/images/notes.png'
// import pen from './../asset/images/pen.png'

import check from './../asset/images/check.png';

import './style/startlearning.scss';

const Start_Learning = () => {
    const highSubgroupRef = useRef(null);
    const [isVisible, setIsVisible] = useState(true);
    const [name, setName] = useState('');

    useEffect(() => {
        highSubgroupRef.current.focus();
        document.getElementById('tnpscButton').click();
      }, []); 
    

    const toggleVisibility = (name) => {
        setName(name)
        // setIsVisible(!isVisible);
    };


    const buttonStyle = {
        backgroundColor: '#FFFFFF',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: '',
        gap:'10px',
        border:'none'
      };
    
      const imgStyle = {
        marginRight: '5px',
        verticalAlign: 'middle',
      };
    
    
    return (

        <>
             <div className="startlearning">

            <div className="wrapper-container">
                
                    <div className="centum-community-container">
                        <div className="whole">

                            <div className="whole_1">
                                <div className="head1">                                
                                <img className='penIcon testmi-penIcon' src={pen} alt="pen"/>
                                            <p>Start Learning Today To Achieve Your Exams </p>
                                        
                                </div>
                                <div className='head2'>
                                     <p>Join Centum and enjoy premium test access at your fingertips!</p>
                                <img className='notesIcon testmi-notesIcon' src={notes} alt="notes"/>

                                </div>
                            </div>
                            {/* img src={India} alt='logo' img src={Upsc} alt='logo' */}
                            <div className="whole2">
                                <div className="highlight_card">
                                     <div className="group11">

                                            <div className="high_subgroup" tabIndex="0" ref={highSubgroupRef} autoFocus>
                                            <button id="tnpscButton" onClick ={()=> toggleVisibility('TNPSC') } style={buttonStyle} ><img src={India} alt='logo'/> TNPSC</button>
                                                {/* <div className="img"><img src={India} alt='logo'/></div> */}
                                                {/* <div className="para"><p>TNPSC</p></div> */}
                                            </div>
                                            <div className="high_subgroup">
                                            <button onClick ={()=>toggleVisibility('UPSC')} style={buttonStyle}><img src={Upsc} alt='logo'/> UPSC</button>
                                            </div>
                                        </div>
                                </div>
                               

                                <div className="card1">
                                    
                                        <div className="group1">
                                            <div className="subgroup testmi-subgroup">
                                            <button onClick ={()=>toggleVisibility('BANK EXAMS')}style={buttonStyle}><img src={Bank} alt='logo'/> Bank Exams</button>

                                              
                                            </div>
                                            <div className="subgroup testmi-subgroup">
                                            <button 
                                                    onClick ={()=>toggleVisibility('NET')}
                                                    style={buttonStyle}
                                                    // onMouseEnter={handleHover}
                                                    // onMouseLeave={handleMouseLeave}
                                                    // ref={(button) => (this.button = button)}
                                                    >
                                                    <img src={Asc} alt='logo' style={imgStyle} /> NET
                                                    </button>
                                            </div>
                                            <img className='star1 blink' src={star} alt='logo' style={{ animationDelay: '1s' }}/>
                                        </div>
                                        <div className="group1">
                                            <div className="subgroup testmi-subgroup">
                                            <button onClick ={()=>toggleVisibility('SSC')} style={buttonStyle}><img src={Ssc} alt='logo'/> SSC</button>
                                            </div>
                                            <div className="subgroup testmi-subgroup">
                                            <button onClick ={()=>toggleVisibility('RAILWAYS')} style={buttonStyle}><img src={Railway} alt='logo'/> Railways</button>
                                            </div>
                                        </div>
                                        <div className="group1">
                                        <img className='star2 blink' src={star} alt='logo' style={{ animationDelay: '2s' }}/>

                                            <div className="subgroup testmi-subgroup">
                                            <button onClick ={()=>toggleVisibility('NEET UG')}style={buttonStyle}><img src={Neet} alt='logo'/>NEET UG</button>
                                            </div>
                                          
                                        </div>
                                </div>
                            </div>

                        </div>



                    </div>


                    {isVisible&&(
                        <div className="details_container">

                        <div className="purchase_container">

                            <div className="package">

                                <div className="type">

                                        <div className="name">
                                            <p>Basic</p>
                                        </div>

                                        <div className="offer">
                                             <img src={save10} alt="" /> 
                                        </div>

                                </div>

                                <div className="price">

                                        <div className="rupee">
                                            <p>INR 199</p>

                                        </div>

                                        <div className="valid">
                                            <p>/ 3 Months</p>
                                        </div>

                                </div>

                            </div>


                            <div className="package">

                                <div className="type">

                                        <div className="name">
                                             <p>Popular</p>
                                        </div>

                                        <div className="offer">
                                             <img src={save15} alt="" /> 
                                        </div>

                                </div>

                                <div className="price">

                                        <div className="rupee">
                                            <p>INR 299</p>

                                        </div>

                                        <div className="valid">
                                            <p>/ 6 Months</p>
                                        </div>

                                </div>

                            </div>

                            <div className="package">

                                <div className="type">

                                        <div className="name">
                                             <p>Extreme</p>
                                        </div>

                                        <div className="offer">
                                             <img src={save20} alt="" /> 
                                        </div>

                                </div>

                                <div className="price">

                                        <div className="rupee">
                                            <p>INR 599</p>

                                        </div>

                                        <div className="valid">
                                            <p>/ 12 Months</p>
                                        </div>

                                </div>

                            </div>

                            <div className="purchase_btn">
                                <img src={purchasebtn} alt="" className='purchasebtnbig'/>
                                 <img src={purchase} alt="" className='purchasebtn'/>
                            </div>

                        </div>


                        <div className="benefits_container">
                            <div className="smallcont">

                           
                            <div className="title">
                               <div>
                                 <p>Discover the benefits of CentumAPP’s {name} Tests</p>
                                </div>
                            </div>
                            <div className="list">
                                    <div className="rows">
                                        <img src={check} alt="" />
                                         <p>Detailed exam performance analysis </p>
                                    </div>
                                    <div className="rows">
                                        <img src={check} alt="" />
                                        <p>Full length, unit wise & chapter wise test </p>
                                    </div>
                                    <div className="rows">
                                        <img src={check} alt="" />
                                        <p>Detailed solution after the test</p>
                                    </div>
                                    <div className="rows">
                                        <img src={check} alt="" />
                                        <p>Take unlimited exams</p>
                                    </div>
                                    <div className="rows">
                                        <img src={check} alt="" />
                                        <p>More than 5000 questions </p>
                                    </div>
                                    <div className="rows">
                                        <img src={check} alt="" />
                                        <p>Master each topics systematically  </p>
                                    </div>
                                    <div className="rows">
                                         <img src={check} alt="" />
                                         <p>Multi language options</p>
                                    </div>
                                    <div className="rows">
                                        <img src={check} alt="" />
                                        <p>Simulated real exam conditions</p>
                                    </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    )}
         </div>   
         </div>
        </>
    )
}
export default Start_Learning;