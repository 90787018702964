import React, { useEffect, useState, useRef } from 'react';
import topFeatureImg from '../asset/images/topfeaturesImg.png'
import './style/topcoachmi-features.scss'
import brownStar from '../asset/images/brownstar.png';
import buestarAboutus from '../asset/images/aboutblueImage.png';
import greenstarAboutus from '../asset/images/aboutGreenStar.png';



const TopFeature_Coachmi = () => {

    return (
        <>
            <div className='coachmi-top-features'>
                <div className='wrapper-container'>
                <div className='coachmi-top-features-header'>
                    <h1>Check out some top features of CoachMI!</h1>
                    <p>Enhance your understanding with our helpful resources and tools!</p>
                </div>
                <img src={greenstarAboutus} alt='arrow' className="coachmi-green-img" />

                <div className='coachmi-top-features-para'>
                    <div id="order-1" className='coachmi-topfeatures-sub-content'>

                        <div className='coachmi-top-features-content' id='box-1'>

                            <h2>Exam Triumph</h2>
                            <p>Join over 700,000 subscribers excelling in every exam!</p>
                        </div>
                        <div className='coachmi-top-features-content' id='box-2'>

                            <h2>Better Understand</h2>
                            <p>Enhance your understanding with our helpful resources and tools!</p>
                        </div>
                        <div className='coachmi-top-features-content' id='box-3'>

                            <h2>Flexibility</h2>
                            <p>Flexibility guaranteed in your study schedule!</p>
                        </div>
                        <img src={brownStar} alt='arrow' className="coachmi-brown-img" />

                    </div>
                    <div id="order-2" className='coachmi-center-features-image'>
                        <img src={topFeatureImg} alt='arrow' className="topFeature-img" />

                    </div>
                    <div id="order-3" className='coachmi-topfeatures-sub-content'>

                        <div className='coachmi-top-features-content' id='box-4'>

                            <h2>Library</h2>
                            <p>Unlock 1000+ hours of subject-wise, topic-wise recorded classes!</p>
                        </div>
                        <div className='coachmi-top-features-content' id='box-5'>

                            <h2>Full Syllabus</h2>
                            <p>We cover 100% of exam syllabus in recorded classes for all exams.</p>
                        </div>
                        <div className='coachmi-top-features-content' id='box-6'>

                            <h2>Current Affairs</h2>
                            <p>Stay ahead with our weekly and monthly exam Current Affairs coverage!</p>
                        </div>
                    </div>
                    <img src={buestarAboutus} alt='arrow' className="coachmi-bluestar-img" />


                </div>
            </div>
            </div>

        </>
    )
}
export default TopFeature_Coachmi;