import React, { useState, useEffect, useRef } from 'react';
import './style/testimonials-community.scss';
import Comma from '../asset/images/comma.png';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getTestimonials } from '../components/reducers/WebsiteSlice';
import { useDispatch, useSelector } from 'react-redux';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from 'jquery';
import "slick-carousel/slick/slick.min.js";


const Testimonials_community = () => {



  const { getTestimonialsData, isLoading, totalDataCount } = useSelector((state) => state.WebsiteSlice);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("testimonials.....");
    dispatch(getTestimonials()).unwrap().then((res) => {
      // slickSliderCarosal()
    })
  }, []);


  const [centerIndex, setCenterIndex] = useState(0);

  const settings = {
    centerMode: true,
    centerPadding: '10px',
    slidesToShow: 5,
    speed: 500,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    autoplay: true,
    responsive: [
      // {
      //   breakpoint: 1440,
      //   settings: {
      //     arrows: false,
      //     slidesToShow: 5,
      //   },
      // },
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
        }
      }
    ],
  };

  const renderSilder = getTestimonialsData.map((data, i) => (
    <>
      <div key={i} className="testimonial-slide" id={`testimonial-slide-${i + 1}`}>
        <div className='testimonial-body'>

          <div className='testimonial-single-card'>
            <div className='testimonial-content'>
              <p className='testimonials-para'>{data.content}</p>
            </div>
            <img src={data.profileImageUrl} alt='profileImage' className="testmonial-img" />
            <div className='testimonial-content'>
              <p className='name'>{data.name}</p>
              <p className='location'>{data.location}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  ))

  return (
    <div className='testimonials_community'>
      <div className='wrapper-container'>

        <div className="testimonials-container-heading">
          Testimonials from Our Students
        </div>
        <div className="comma-description">
          <div className="testmonials-container-comma">
            <div className="testimonial-separator-right"></div>
            <div className="comma">
              <img src={Comma} alt='logo' className="logo-img" />
            </div>
            <div className="testimonial-separator-left"></div>
          </div>
        </div>

        <div className='testimonials-card'>
          <Slider {...settings}>
            {renderSilder}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Testimonials_community;
