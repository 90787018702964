import React, { useState, useEffect } from "react";
import "./style/login-container-landing.scss";
import playstoreicon from "../asset/images/playstore.svg";
import appstoreicon from "../asset/images/appstore.svg";
import leftarrow from "../asset/images/leftarrow.png";
import boyimage from "../asset/images/doll animateshadow_1.png";
import groupstar from "../asset/images/Group 1000001837.png";
import appmockup from "../asset/images/Unlock the Ultimate Learning Experience.png";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Select, Space, message } from "antd";
import Input, { PhoneNumber } from "react-phone-number-input/input";
import { createLeads } from "../components/reducers/WebsiteSlice";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-number-input/input";

// import star from '../star.svg';

const { Search } = Input;

const Login_container_landing = () => {
  const [phoneNumber, setPhoneNumber] = useState();
  const dispatch = useDispatch();

  const isValidPhoneNumber = (phoneNumber) => {
    let regex = /^\+91\d{10}$/;
    return regex.test(phoneNumber);
  };

  const handleSubmit = () => {
    if (!isValidPhoneNumber(phoneNumber)) {
      return;
    }
    const cleanedPhoneNumber = phoneNumber.replace("+91", "");
    let data = {
      mobileNumber: Number(cleanedPhoneNumber),
    };
    dispatch(createLeads(data))
      .unwrap()
      .then((res) => {
        if (res) {
          setPhoneNumber();
        }
      });
  };

  return (
    <>
      <div class="container-login">
      <div class="wrapper-container">
        <div className="login-wrapper">
          <div className="section-header">
            Unlock The Ultimate{" "}
            <span class="learning">Learning Experience</span>
          </div>

          <div className="phonenumber">
            <Input
              type="tel"
              className="phone-number-input"
              containerStyle={{ backgroundColor: "black" }}
              placeholder="Enter Phone Number"
              withDarkTheme
              withShadow
              value={phoneNumber}
              maxLength={15}
              minLength={15}
              country="IN"
              international
              withCountryCallingCode
              onChange={(e) => {
                setPhoneNumber(e);
              }}
            />
            <Button
              type="primary"
              shape="circle"
              className="cta-btn"
              onClick={handleSubmit}
            >
              <img src={leftarrow} alt="" />
            </Button>
            {/* <img src={backicon} alt="" srcset="" /> */}
          </div>

          <div className="App-links">
            <div className="quick-actions">
              <a href="https://play.google.com/store/apps/details?id=academy.centum">
                <img src={playstoreicon} alt="" srcset="" />
              </a>
              <a href="">
                <img src={appstoreicon} alt="" srcset="" />
              </a>
            </div>
          </div>
        </div>
        <div className="group-star">
          <img src={groupstar} alt="" srcset="" />
        </div>
        <div className="boy-character">
          <img src={boyimage} alt="" srcset="" />
        </div>
        <div className="Image-wrapper">
          <img src={appmockup} alt="" srcset="" />
        </div>
      </div>
      </div>
    </>
  );
};
export default Login_container_landing;
