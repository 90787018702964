            import React, { useState } from 'react';
import './style/landing-page-best-teacher.scss';
import pencil from '../asset/images/ourTeamPencil.png'
import isolation from '../asset/images/Our_Team_Isolation.png'
import teacherImage from '../asset/images/enhance 1group-img-testimoni.png'
import landingBackground from '../asset/images/teacher_image_background.png'
import groupimg from '../asset/images/banner-imggroup-img-3.png'
import objectImage from '../asset/images/OBJECTS.png'
import curveimg from '../asset/images/Vector 1669curve-img-landingpage.png';
import bookTeacher from '../asset/images/bookTeacherImg.png';
import bulbTeacher from '../asset/images/bulbTeacherImg.png';

// import group2 from '../asset/images/enhance 2.png'


const Landing_page_best_teacher = () => {

    return (
        <>
        <div className="lan-page-padding">  
        <div className="wrapper-container">  


            <div className="landing-page-container">
                <div className="landing-image">
                    <img src={pencil} alt='arrow' className="pencil-img" />
                    <img src={bulbTeacher} alt='arrow' className="bulbTeacher-img" />

                    </div>

                <div className="isolation-landing-image">
                    <img src={bookTeacher} alt='arrow' className="isolation-img" />
                    <img src={isolation} alt='arrow' className="mbl-isolation-img" />

                    </div>
                <div className="lan-round-img">
                <img src={objectImage} alt='arrow' className="lan-obj-img" />

                </div>
        <div className="background-landing">

                <div className="lan-page-overall">
                <div className="lan-page-header">
                    Our Best Teachers
                </div>
                </div>

                <div className="lan-two-page-overall">
                <div className="lan-page-text">
                Acquire top-notch knowledge with our exceptional instructors. Learn from the best for an unparalleled educational experience!
                    
                </div>
                </div>

                 <div className="lan-group-img">
                 <img src={groupimg} alt='arrow' className="grp-teacher-img" /> 
             {/* <img src={group2} alt='arrow' className="grp-teacher-img" /> */}
                 

                 </div>
               
                
        </div>
        

                
                       

            </div>
            </div>
            </div>
        </>
    )
}
export default Landing_page_best_teacher;
















