import React, {useRef,useState,useEffect } from 'react';

import India from './../asset/images/india.png';
import Upsc from './../asset/images/upsc-logo-new.png';
import Bank from './../asset/images/bank.png';
import Asc from './../asset/images/ace.png';
import Ssc from './../asset/images/ssc.png';
import Railway from './../asset/images/railway.png';
import Neet from './../asset/images/neer.png';
import pen from './../asset/images/pen.png';
import notes from './../asset/images/notes.png';
import save10 from './../asset/images/save10.png';
import save15 from './../asset/images/save15.png';
import save20 from './../asset/images/save20.png';
import purchasebtn from './../asset/images/purchasebtn.png';
import purchase from './../asset/images/purchase2.png';
import coachmiwhite_star from '../asset/images/whitestar.png';
import check from './../asset/images/check.png';
import coachmi_heartImg from '../asset/images/heart-community.png';
import coachmi_followImg from '../asset/images/follow-community.png';
import './style/coachmi-startlearning.scss'

const CoachMi_Learning = () => {
    const coachMiHighSubgroupRef = useRef(null);
    const [isVisible, setIsVisible] = useState(true);

    const toggleVisibility = () => {
        // setIsVisible(!isVisible);
    };

    useEffect(() => {
        coachMiHighSubgroupRef.current.focus();
        document.getElementById('tnpscButton').click();
      }, []); 

    const buttonStyle = {
        backgroundColor: '#FFFFFF',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: '',
        // padding: '10px',
        gap: '10px',
        border: 'none'
    };

    const imgStyle = {
        marginRight: '5px',
        verticalAlign: 'middle',
    };



    return (

        <>
            <div className="coachmi-startlearning">

<div className='wrapper-container'>

                <div className="coachmi-community-container">
                    <div className="coachmi-whole">

                        <div className="whole_1">
                            <div className="coachmi-head1">

                                <p>Start Learning Today To Achieve Your Exams </p>

                            </div>
                            <img src={coachmi_followImg} alt='logo' className="coachmi-follow-img1" />

                            <div className='coachmi-head2'>
                                <p>Join Centum and enjoy premium test access at your fingertips!</p>
                            </div>
                        </div>
                        <img src={coachmiwhite_star} alt='logo' className="coachmi-whiteStar-img1" />

                        <img src={pen} alt='logo' className="coachmi-logo-img1" />

                        <img src={notes} alt='logo' className="coachmi-logo-img2" />

                        <img src={coachmi_heartImg} alt='logo' className="coachmi-heart-img2" />

                        <div className="coachmi-whole2">
                            <div className="coachmi-highlight_card">
                                <div className="coachmi-group11">

                                    <div className="coachmi-high-subGroup " tabIndex="0" ref={coachMiHighSubgroupRef} autoFocus>
                                        <button id='tnpscButton' className='coachMi-learning-button' onClick={toggleVisibility}
                                            style={buttonStyle}

                                        ><img src={India} alt='logo' /> TNPSC</button>

                                    </div>
                                    <div className="coachmi-high-subGroup ">
                                        <button className='coachMi-learning-button' onClick={toggleVisibility} style={buttonStyle}><img src={Upsc} alt='logo' /> UPSC</button>
                                    </div>
                                </div>
                            </div>


                            <div className="coachiMi-learning-card1">
                                <div className="learning-coachmi-group1">
                                    <div className="coachMi-learning-subgroup">
                                        <button className='coachMi-learning-button' onClick={toggleVisibility} style={buttonStyle}>
                                            <img src={Bank} alt='logo' /> Bank Exams</button>
                                    </div>
                                    <div className="coachMi-learning-subgroup">
                                        <button
                                            className='coachMi-learning-button'
                                            onClick={toggleVisibility}
                                            style={buttonStyle}

                                        >
                                            <img src={Asc} alt='logo' style={imgStyle} /> NET
                                        </button>
                                    </div>
                                </div>
                                <div className="learning-coachmi-group1">
                                    <div className="coachMi-learning-subgroup">
                                        <button className='coachMi-learning-button' onClick={toggleVisibility} style={buttonStyle}><img src={Ssc} alt='logo' /> SSC</button>
                                    </div>
                                    <div className="coachMi-learning-subgroup">
                                        <button className='coachMi-learning-button' onClick={toggleVisibility} style={buttonStyle}><img src={Railway} alt='logo' /> Railways</button>
                                    </div>
                                </div>
                                <div className="learning-coachmi-group1">
                                    <div className="coachMi-learning-subgroup">
                                        <button className='coachMi-learning-button' onClick={toggleVisibility} style={buttonStyle}><img src={Neet} alt='logo' />NEET UG</button>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>



                </div>


                {isVisible && (
                    <div className="price-container">
                        <div className="price-card">
                            <div className="head-desc-price">
                                <div className=" green price-heading">Basic</div>
                                <div className="price-description">Start your learning adventure . Explore foundational knowledge affordably</div>
                                <div className="price-price-container">
                                    <div className="price-price">INR 199</div>
                                    <div className="price-month">/3months</div>
                                </div>
                            </div>
                            <div className="price-btn-get-started">Get Started</div>
                        </div>

                        <div className="price-card">
                            <div className="head-desc-price">
                                <div className='mostpopular-content'>
                                    <div className=" blue price-heading">Standard </div>
                                    <div className='popular-para-learning'>  Most popular</div>
                                </div>
                                <div className="price-description">Dive deeper into learning with ease. Explore diverse topics affordably</div>
                                <div className="price-price-container">
                                    <div className="price-price">INR 299</div>
                                    <div className="price-month">/ 6 Months</div>
                                </div>
                            </div>
                            <div className="price-btn-get-started">Get Started</div>
                        </div>
                        <div className="price-card">
                            <div className="head-desc-price">
                                <div className="violet price-heading">Premium</div>
                                <div className="price-description">Unlock advanced knowledge effortlessly. Achieve your learning goals affordably</div>
                                <div className="price-price-container">
                                    <div className="price-price">INR 599</div>
                                    <div className="price-month">/ 12 Months</div>
                                </div>
                            </div>
                            <div className="price-btn-get-started">
                                <p>Get Started</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            </div>
        </>
    )
}
export default CoachMi_Learning;