import React, { useState, useEffect } from 'react';
import './style/count-community.scss';
import Count from '../asset/images/count-community.png';
import Meeting from '../asset/images/count-meeting.png';
import Population from '../asset/images/count-population.png';

const Count_community = () => {
    const initialCounts = [
        { description: "Total Community Users", count: "100" },
        { description: "Active Online Now", count: "50" },
        { description: "Growth of the Community over the last time", count: "20" }
    ];

    const [counts, setCounts] = useState(initialCounts.map(() => 0)); // Initialize counts with zeros
    const maxCounts = initialCounts.map(({ count }) => parseInt(count));

    useEffect(() => {
        const interval = setInterval(() => {
            setCounts(prevCounts => prevCounts.map((count, index) => {
                const maxCount = maxCounts[index];
                return count < maxCount ? count + 1 : maxCount;
            }));
        }, 100); // Change the interval duration as needed

        return () => clearInterval(interval);
    }, []);

    return (
        <div className='overall-count-community'>
        <div className='wrapper-container'>

            <div className="count-wrap-container">
                {counts.map((count, index) => (
                    <div className="count-wrap-container-content" key={index}>
                        <div className="count-content-img">
                            {index === 0 && <img src={Count} alt='Count' className="count-item-icon" />}
                            {index === 1 && <img src={Meeting} alt='Count' className="count-item-icon" />}
                            {index === 2 && <img src={Population} alt='Count' className="count-item-icon" />}
                        </div>
                        <div className="count-img-description">
                            <div className="count-description-text">
                                {initialCounts[index].description}
                            </div>
                        </div>
                        <div className="count-comunity-num">
                            <div className="conut-community-num-text">
                                {`${count}${index < 2 ? '+' : '%'}`}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        </div>
    );
};

export default Count_community;
