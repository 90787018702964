import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.scss';
import App from './App';
import { store } from "./components/store/store";
import { Provider } from 'react-redux';
import axios from "axios";
import repotWebVitals from "../src/repotWebVitals";
import { BrowserRouter, useNavigate, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
let history = createBrowserHistory();


// axios.defaults.baseURL = "https://staging-website.centum.academy/api/";
axios.defaults.baseURL = "https://staging.centum.academy/api/"; 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <HistoryRouter history={history}>
        <App />
      </HistoryRouter>
    </Provider>
  </React.StrictMode>
);

repotWebVitals();
