import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './style/explore-more.scss';
import video from '../asset/images/video 1.png';
import community from '../asset/images/partners 1.png';
import testMi from '../asset/images/tetmiimage.png';

const CardData = [
  {
    imgSrc: video,
    alt: 'logo-video',
    heading: 'Coachmi',
    text: 'Unleash your potential with our curated courses guided by industry pros. Elevate your skills and seize the path to success.',
    link: '/coachMi'
  },
  {
    imgSrc: community,
    alt: 'logo-community',
    heading: 'Community',
    text: 'Explore Expert-Led Learning for a journey to success! Join our vibrant Aspiring Community, connect, and thrive together!',
    link: '/community'
  },
  {
    imgSrc: testMi,
    alt: 'logo-testmi',
    heading: 'TestMI',
    text: 'Dive into the future with our Complete Test Solution! Unleash your full potential as you conquer challenges effortlessly.',
    link: '/testMi'
  },
];

const Explore_More = () => {
  const location = useLocation();
  console.log(location)
  const currentPage = location.pathname;

  let currentCards = [];

  if (currentPage === '/testMi') {
    currentCards = [CardData[0], CardData[1]]; 
  } else if (currentPage === '/coachMi') {
    currentCards = [CardData[2], CardData[1]]; 
  } else if (currentPage === '/community') {
    currentCards = [CardData[0], CardData[2]]; 
  }

  return (
    <div className="explore-more">
    <div className="wrapper-container">

      <div className="explore">
        <p className='heading'>Explore More</p>
        <p className='exploretext'>Discover fresh ideas and embrace the endless opportunities that come with learning more</p>
      </div>
      <div className='explorecardview'>
        {currentCards.map((card, index) => (
          <div key={index} className="explorecard">
            <img src={card.imgSrc} alt={card.alt} />
            <p className='cardheading'>{card.heading}</p>
            <p className='cardtext'>{card.text}</p>
            <Link to={card.link}><button className='cardbutton'>Explore</button></Link>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default Explore_More;
