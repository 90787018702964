import React, { useEffect, useState, useRef } from "react";
import './style/hubschool-whycentum.scss';
import hubschoolGirlImg from  '../asset/images/hubschoolgirl.png';
import dymandLogo from '../asset/images/hubdymand.png';



const Hubschool_Whycentum = () => {
   
    const hubCentumData = [
        {
          imgSrc: dymandLogo,
          text: 'Partner with CentumAPP by paying a nominal Annual Subscription.',
        },
        {
          imgSrc: dymandLogo,
          text: 'Golden opportunity for rural & semi urban school.',
        },
        {
          imgSrc: dymandLogo,
          text: '5 National level tests with all partner schools & independent students (Questions will be available in both Tamil & English).',
        },
        {
            imgSrc: dymandLogo,
            text: 'The School children will get overall ranking as per NEET/IIT norms.',
          },
          {
            imgSrc: dymandLogo,
            text: 'Result will be released instantly with inclusion of negative marks.',
          },
          {
            imgSrc: dymandLogo,
            text: 'The School can do away with sub-standard local question papers.',
          },
          {
            imgSrc: dymandLogo,
            text: 'Chapter-Wise,Subject-Wise, full syllabus question papers can be created by your own school Centum APP incharge.',
          },
          {
            imgSrc: dymandLogo,
            text: 'Print out of the question papers can be taken & exam can be conducted in offline mode also with your school name.',
          },
          {
            imgSrc: dymandLogo,
            text: 'A detailed yearly exam schedule will be given. Online classes will also be provided.',
          },
          {
            imgSrc: dymandLogo,
            text: 'Yearly 2 motivation-cum-mentor programme by expert will be conducted in online/offline mode.',
          },
          {
            imgSrc: dymandLogo,
            text: 'Technical support will be provided for OMR Scanner & Result declaration on demand.',
          },
          {
            imgSrc: dymandLogo,
            text: 'Complete Support for Repeaters.',
          },
      ];



  return (
    <>

    <div className="hubschool-whycentum">
    <div className="wrapper-container">

        <div className="hubschool-whycentum-content">
            <p className="hubschool-header">Why CentumAPP Hub School?</p>

            <div className='hubschool-para-container'>
        {hubCentumData.map((card, index) => (
          <div key={index} className="hubschool-image-loop">
            <img src={card.imgSrc} alt={card.alt} />
            <p className='paratext'>{card.text}</p>
          </div>
        ))}
      </div>
          
        </div>
        <div className="hubschool-whycentum-image">
        <img src={hubschoolGirlImg} alt='hubschoolGirlImg' className="hubschoolGirlImg-image" />
      
        </div>
    </div>
</div>
    </>
  );
};
export default Hubschool_Whycentum;
