import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './style/banner-advertisement.scss';
import banner from './../asset/images/Website advertising banner eng 2.png';
import icon from './../asset/images/Icon (2).svg';
import { currentAffairs, getAnnoucement, downloadPdf } from './reducers/WebsiteSlice';
import line from './../asset/images/Line 30.png';
import Slider from "react-slick";


const Banner_Advertisement = () => {
    const { currentAffairsData, isLoading, totalCount, getAnnoucementData, getPdfData } = useSelector((state) => state.WebsiteSlice);

    const settings = {
        slidesToShow: 1,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,

    }

    useEffect(() => {
        dispatch(getAnnoucement());
        dispatch(currentAffairs());
    }, []);

    const handleDownloadPdf = () => {
        dispatch(downloadPdf()).unwrap().then((res) => {

            // const blob = new Blob([new Uint8Array(res).buffer], { type: 'application/pdf' });
            // const url = URL.createObjectURL(blob);
            // const link = document.createElement('a');
            // link.href = url;
            // link.download = 'current_affairs.pdf';
            // document.body.appendChild(link);
            // link.click();
            // URL.revokeObjectURL(url);

            // const buffer = Buffer.from(res);
            const uint8Array = new Uint8Array(res);
            const blob = new Blob([uint8Array], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'current_affairs.pdf');
            link.textContent = 'Download PDF';
            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(url);

            // const url = window.URL.createObjectURL(new Blob([new Uint8Array(res).buffer]));
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', 'current_affairs.pdf');
            // document.body.appendChild(link);
            // link.click();

            // var bytes = new Uint8Array(res); // pass your byte response to this constructor
            // var blob = new Blob([bytes], { type: "application/pdf" });// change resultByte to bytes
            // var link = document.createElement('a');
            // link.href = window.URL.createObjectURL(blob);
            // link.download = "current_affairs.pdf";
            // link.click();
        })
        // console.log(';;;;;;;;;;;;;;;', getPdfData)
        // var bytes = new Uint8Array(resultByte); // pass your byte response to this constructor
        // var blob = new Blob([bytes], { type: "application/pdf" });// change resultByte to bytes
        // var link = document.createElement('a');
        // link.href = window.URL.createObjectURL(blob);
        // link.download = "myFileName.pdf";
        // link.click();

        // console.log('Download PDF link clicked');
        // dispatch(downloadPdf());
        // console.log(';;;;;;;;;;;;;;;', getPdfData.pdfUrl)
        // const tempLink = document.createElement('a');
        // // const objectURL = URL.createObjectURL(pdfBlob);

        // tempLink.href = getPdfData.pdfUrl;
        // tempLink.setAttribute('download', 'current_affaires.pdf');
        // tempLink.setAttribute('target', '_blank');
        // document.body.appendChild(tempLink);
        // tempLink.click();
        // document.body.removeChild(tempLink);
    };

    const dispatch = useDispatch()
    const [pdfData, setpdfData] = useState([]);

    // const handleDownloadPdf = async () => {
    //       await dispatch(downloadPdf()).unwrap().then(async (res) => {
    //     setpdfData(res.data.data)

    //   const buffer = await res;

    //   function arrayBufferToBase64( buffer ) {
    //     var binary = '';
    //     var bytes = new Uint8Array( buffer );
    //     console.log(bytes)
    //     var len = bytes.byteLength;
    //     for (var i = 0; i < len; i++) {
    //       binary += String.fromCharCode( bytes[ i ] );
    //     }
    //     return window.btoa( binary );
    //   }
    //   const base64 =  arrayBufferToBase64(buffer.data)
    //   const blob = new Blob([buffer], { type: 'application/pdf' })
    //   const pdfUrl = URL.createObjectURL(blob);
    //       const a = document.createElement('a');
    //       a.href = 'data:application/pdf;base64,'+base64;
    //       a.download = 'current-affairs.pdf';
    //       document.body.appendChild(a);
    //       a.click();
    //       document.body.removeChild(a);

    //       setTimeout(() => {
    //       triggerPdfDownload();
    //     }, 1000)
    //     setTimeout(() => {
    //       isDownloaded()
    //     }, 5000)
    //   })
    // }
    const isDownloaded = () => {
        setpdfData([])
    }

    const triggerPdfDownload = () => {
        const downloadElement = document.getElementById('currentAffairsDownload');
        const downloadTag = downloadElement.getElementsByTagName('a')[0];
        if (downloadTag) {
            downloadTag.click();
        }
    };

    return (
        <>
            <div className="addban">

                <div className='banner-image-divition'>
                    <Slider {...settings}>
                        {getAnnoucementData.map((data, i) => (
                            <div className="addban-image" key={i}>
                                <div className="leftcont">
                                    <img src={data.imageUrl} alt='logo' className="logo-img" />
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>

                <div className="rightcont">
                    <div className="link">
                        <p>Current Affairs today</p>
                        <a href=''><img src={icon} alt="icon" className='icon' /></a>

                        <a id="currentAffairsDownload" type="button" href='#' onClick={() => handleDownloadPdf()} className='word'>Download PDF </a>
                    </div>
                    <div className="footer-sepline"></div>

                    <div className="scroll">
                        <div className="contents">
                            {currentAffairsData.map((item, index) => (
                                <div className="topics" key={index}>
                                    <div className='heading'>
                                        <h2>{item.title}</h2>
                                    </div>
                                    <div className="para1">
                                        <p>{item.content}</p>
                                    </div>
                                </div>
                            ))}
                            {/* <div className="viewall">
                            <a href="">View all</a>
                        </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Banner_Advertisement;