import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


//get current affaires
export const currentAffairs = createAsyncThunk('website/currentAffairs', async () => {
    try {
        let getAllData = await axios.get('website/getActiveCurrentAffairs').then((res) => {
            if (res.data.success) {
                return res.data.data
            }
        })
        return getAllData

    } catch (error) {
    }
})



export const getAnnoucement = createAsyncThunk('website/getAnnoucement', async () => {
    try {
        let getData = await axios.get('/website/getAnnouncementActive').then((res) => {

            if (res.data.success) {
                return res.data.data
            }
        })
        return getData

    } catch (error) {
        console.log(error, 'err');
    }
})


export const keyFeatures = createAsyncThunk('website/keyFeatures', async () => {
    try {
        let getAllData = await axios.get('/website/getKeyFeaturesActive').then((res) => {
            if (res.data.success) {
                return res.data.data
            }
        })
        return getAllData

    } catch (error) {
    }
})

export const createLeads = createAsyncThunk('website/createLeads', async (payload, thunk) => {
    try {
        let getAllData = await axios.post('/website/createLeads', payload).then((res) => {
            if (res.data) {
                return res.data.data
            }
        })
        return getAllData

    } catch (error) {
    }
})


export const institutioncontactregister = createAsyncThunk(
    'website/institutioncontactregister',
    async (payload) => {
        try {
            console.log('Payload:', payload);
            const response = await axios.post('/website/createInstitutionContactRegister', payload).then((res) =>{
                if (res) {
                    return res.data
                }
            })
            return response
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    }
);

export const getproducts = createAsyncThunk('website/getproducts', async () => {
    try {
        let getAllData = await axios.get('/website/getActiveProducts').then((res) => {
            if (res.data.success) {
                return res.data.data
            }
        })
        return getAllData

    } catch (error) {
        console.log(error, 'err');
    }
})


export const getStatusFeatures = createAsyncThunk('website/getStatusFeatures', async () => {
    // console.log("++++++++++++");
    try {
        let getAllData = await axios.get('/website/getActiveStats').then((res) => {
            // console.log("//////", res);
            if (res.data.success) {
                return res.data.data
            }
        })
        return getAllData

    } catch (error) {
        console.log(error, 'err');
    }
})


export const getTutorial = createAsyncThunk('website/getTutorial', async () => {
    try {
        let getAllData = await axios.get('/website/getActiveTutorials').then((res) => {
            if (res.data.success) {
                return res.data.data
            }
        })
        return getAllData

    } catch (error) {
        console.log(error, 'err');
    }
})

export const getTestimonials = createAsyncThunk('website/getTestimonials', async () => {
    console.log("++++++++++++");
    try {
        let getAllData = await axios.get('/website/getActiveTestimonials').then((res) => {

            console.log("//////", res);
            if (res.data.success) {
                return res.data.data
            }
        })
        return getAllData

    } catch (error) {
        console.log(error, 'err');
    }
})
export const getCommunity = createAsyncThunk('website/getCommunity', async () => {
    console.log("++++++++++++");
    try {
        let getData = await axios.get('/website/getActiveCommunity').then((res) => {

            console.log("//////", res);
            if (res.data.success) {
                return res.data.data
            }
        })
        return getData

    } catch (error) {
        console.log(error, 'err');
    }
})


export const createNewsLetter = createAsyncThunk('website/createNewsLetter', async (payload, thunk) => {
    try {
        let getAllData = await axios.post('/website/createNewsLetter', payload).then((res) => {
            if (res.data) {
                return res.data.data
            }
        })
        return getAllData

    } catch (error) {
    }
})
// export const downloadPdf = createAsyncThunk('website/downloadPdf', async (payload, thunk) => {
//     try {
//         console.log(payload, "bharathi pdf");
//         let getAllData = await axios.get('/website/getActiveCurrentAffairsPdf', payload).then((res) => {
//             if (res.data.success) {
//                 return res.data.data
//             }
//         })
//         return getAllData

//     } catch (error) {
//     }
// })

export const downloadPdf = createAsyncThunk('website/downloadPdf', async () => {
    try {
        let getData = await axios.get('/website/getActiveCurrentAffairsPdf').then((res) => {
            if (res.data.success) {
                return res.data.data
            }
        })
        return getData

    } catch (error) {
        console.log(error, 'err');
    }
})

const initialState = {

    // adminMaster: [],
    isLoading: false,
    totalDataCount: 0,
    currentAffairsData: [],
    keyFeaturesData: [],
    getTestimonialsData: [],
    getproductsData: [],
    contactRegisterData: [],
    getStatusData: [],
    getTutorialData: [],
    getCommunityData: [],
    getAnnoucementData: [],
    getPdfData: []
}
const WebsiteSlice = createSlice({
    name: 'WebsiteSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder
            .addCase(currentAffairs.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(currentAffairs.fulfilled, (state, action) => {
                state.isLoading = false;
                state.currentAffairsData = action.payload;
            })
            .addCase(currentAffairs.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(keyFeatures.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(keyFeatures.fulfilled, (state, action) => {
                state.isLoading = false;
                state.keyFeaturesData = action.payload;
            })
            .addCase(keyFeatures.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getTestimonials.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getTestimonials.fulfilled, (state, action) => {
                console.log("testimonial action", action.state);
                state.isLoading = false;
                state.getTestimonialsData = action.payload;
                console.log(".........", state.getTestimonialsData);
            })
            .addCase(getTestimonials.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getproducts.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getproducts.fulfilled, (state, action) => {
                state.isLoading = false;
                state.getproductsData = action.payload;
            })
            .addCase(getproducts.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getAnnoucement.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAnnoucement.fulfilled, (state, action) => {
                state.isLoading = false;
                state.getAnnoucementData = action.payload;
            })
            .addCase(getAnnoucement.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getStatusFeatures.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getStatusFeatures.fulfilled, (state, action) => {
                state.isLoading = false;
                state.getStatusData = action.payload;
            })
            .addCase(getStatusFeatures.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getTutorial.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getTutorial.fulfilled, (state, action) => {
                state.isLoading = false;
                state.getTutorialData = action.payload;
            })
            .addCase(getTutorial.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(getCommunity.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getCommunity.fulfilled, (state, action) => {
                state.isLoading = false;
                state.getCommunityData = action.payload;
            })
            .addCase(getCommunity.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(downloadPdf.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(downloadPdf.fulfilled, (state, action) => {
                state.isLoading = false;
                state.getPdfData = action.payload;
            })
            .addCase(downloadPdf.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(createLeads.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createLeads.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(createLeads.rejected, (state) => {
                state.isLoading = false;
            })
            .addCase(institutioncontactregister.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(institutioncontactregister.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(institutioncontactregister.rejected, (state) => {
                state.isLoading = false;
            });



    },

})
export default WebsiteSlice.reducer