import React, { useState,useEffect } from 'react';
import './style/footer-community.scss';
import Footerlogo from '../asset/images/logo.svg';
import Footerlinkedin from '../asset/images/inImage.png';
import Footerinstagram from '../asset/images/instagram.png';
import Footerxpath from '../asset/images/xImage.png';
import Footeryoutube from '../asset/images/youtubeImage.png';
import Footerfacebook from '../asset/images/facebook.png';
import { createNewsLetter } from '../components/reducers/WebsiteSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Input ,Form, message } from 'antd';
import { Formik,useFormik } from "formik";
import * as yup from 'yup';





const Footer_community = () => {



   const { createLeadsData, isLoading, totalDataCount } = useSelector((state) => state.WebsiteSlice);

   const dispatch = useDispatch();
   const [email, setEmail] = useState();

   const initialValues = {
    email: ""
  };

  const validationSchema = yup.object({
        email: yup.string().email('Invalid Email').required("Email is Required"),
  });

   const formik = useFormik({ 
      initialValues,
      validationSchema,
      onSubmit:(values) =>{
            let data = {
               email:values.email
         }
     dispatch(createNewsLetter(data)).unwrap().then((res) => {
         message.success(res.message)
         formik.resetForm();
     })} 
   })



    return (
        <>
            <div className='footer-container flex'>
            <div className="wrapper-container">

                <div className="footer">
                <div className="footer-left-container">
                    <div className="footer-icon">
                    <div className="footer-logo">
                 <img src={Footerlogo} alt='arrow' className="logo" />
                 </div>
                 <div className="footer-name footer-color">
                    CentumAPP
                 </div>
                    </div>
                
                 <div className="footer-newsletter footer-color">
                 Join our newsletter to stay up to date on features and releases.
                 </div>
                 <Formik>
                     <Form>
                         <div className="footer-search flex">
                            <div className="footer-search-input">
                               <Input className='footer-input' 
                                 type="text" 
                                 id='email' 
                                 name='email' 
                                 placeholder='Enter your email'
                                 onChange={formik.handleChange} 
                                 onBlur={formik.handleBlur} 
                                 value={formik.values.email}
                               />
                               {formik.touched.email && formik.errors.email ? <div className='errorMessage' style={{ color: 'red',fontSize:'12px',marginLeft:'10px' }}>{formik.errors.email}</div> : null}
                              
                            </div>
                            <div className="btn">
                               <button className='footer-btn footer-color' type="submit" onClick={formik.handleSubmit}>Subscribe</button>
                            </div>
                         </div>
                     </Form>
                 </Formik>
                 
                  

                 <div className="footer-policy footer-color">
                 By subscribing you agree to with our <span className='privacy'>Privacy Policy</span> and provide consent to receive 
                 <p className='footer-color'> updates from our company.</p>
                 </div>

                 <div className="footer-follow footer-color">
                 Follow Us on
                 </div>

                 <div className="footer-icons flex">
                    <div>
                 <img src={Footerinstagram} alt='arrow' className="logo" />
                    </div>
                    <div>
                 <img src={Footerfacebook} alt='arrow' className="logo" />
                    </div>
                    <div>
                 <img src={Footerxpath} alt='arrow' className="logo" />
                    </div>
                    <div>
                 <img src={Footeryoutube} alt='arrow' className="logo" />
                    </div>
                    <div>
                 <img src={Footerlinkedin} alt='arrow' className="logo" />
                    </div>

                 </div>

                </div>
                <div className="footer-right-container flex">
                    <div className="footer-link flex">
                    <div className="footer-links flex">
                        <div><h4 className='footer-header'>Links</h4></div>
                        <div><a  className='footer-aTag footer-color' href="/community">Community</a></div>
                        <div><a className='footer-aTag footer-color' href="/testMi">TestMI</a></div>
                        <div><a className='footer-aTag footer-color' href="/coachMi">CoachMI</a></div>
                        <div><a className='footer-aTag footer-color' href="">Exam</a></div>
                        <div><a className='footer-aTag footer-color' href="">Study Material</a></div>
                        <div><a className='footer-aTag footer-color' href="">Success Story</a></div>
                    </div>
                    <div className="footer-link-company ">
                       <div className="footer-company">
                       <div><h4 className='footer-header-company footer-color'>Company</h4></div>
                       <div><a className='footer-aTag-company footer-color' href="/aboutus">   About Us</a></div>
                        <div><a className='footer-aTag-company footer-color' href="/coachMi">Careers</a></div>
                         </div>
                         <div className="footer-other">
                         <div><h4 className='footer-header-other footer-color'>Others</h4></div>
                       <div><a className='footer-aTag-other footer-color' href="/privacyPolicy">Privacy Policy</a></div>
                        <div><a className='footer-aTag-other footer-color' href="/termsCondition">Terms of Service</a></div>
                         </div>
                    </div>
                    </div>
                   
                </div>
                </div>

                <div className="footer-sep"></div>
                <div className="footer-rights footer-color">
                © 2024 CentumApp. All rights reserved.
                </div>

           </div>
</div>
        
            
        </>
    )
}
export default Footer_community;

