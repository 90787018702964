import React, { useEffect, useState, useRef } from "react";
import "./style/coachMi-banner.scss";
import rocket from "../asset/images/rocketweb.png";
import rocketmobile from "../asset/images/Frame rocket.svg";
import arrow from "../asset/images/testmibannerarrow.png";
import book from "../asset/images/testmibannerbook.png";
import bubble from "../asset/images/tetmibubble.png";
import youtubeCoachmi from "../asset/images/Frame youtube.svg";
import messageCoachmi from "../asset/images/Frame message.svg";
import testmiStaff from "../asset/images/testmi_teacher.png";

const CoachMI_Banner = ({CentumTestmiRoute}) => {
  const [textIndex, setTextIndex] = useState(0);

  const textArray = [
    "Edge closer to success by acing exams with <span style='color:#2D81F7;'>Testmi</span> additional practice tests.",
    "Unlock success by mastering exams with  <span style='color: #2D81F7;'>Testmi.</span> and enjoy exclusive perks",
    "Trust  <span style='color: #2D81F7;'>Testmi</span> to guide you towards achieving your academic goals efficiently and effectively",
    "<span style='color:#2D81F7;'>Testmi</span> ensures you're well-prepared and confident on exam day.",
    "Whichever be the <span style='color:#2D81F7;'>institutes</span> you are studying in, take tests with us!",
    "<span style='color:#2D81F7;'>Testmi</span> is your ultimate exam prep test partner!",
    "Take  endless  examination  with <span style='color:#2D81F7;'>Testmi</span> is your ultimate exam prep test partner!",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % textArray.length);
    }, 700);

    return () => clearInterval(interval);
  }, []);

  const testmiExploreClick =()=>{
    if(CentumTestmiRoute){
      CentumTestmiRoute();
    }

  }

  return (
    <>
      <div className="banner-headercontain-section">
        <div className="wrapper-container">
        <div className="banner-container-section">
          <div
            className="banner-text-section testMi-banner-Text"
            dangerouslySetInnerHTML={{ __html: textArray[textIndex] }}
          />

          <div className="banner-btn-section">
            <button className="ban-btn-clr-section" onClick={testmiExploreClick}>Explore </button>
            <img
              src={rocketmobile}
              alt="rocket"
              className="rocketMobile-image-section paper-rocket-testmi"
            />
          </div>
        </div>

        <div className="banner-image-section">
          <img src={rocket} alt="rocket" className="rocketWeb-image" />
          <img src={arrow} alt="arrow" className="arrow-img-section" />
        </div>

        <div className="banner-right-section">
          <img src={testmiStaff} alt="arrow" className="second-img-imgage testmi-staff-img" />
          <img src={bubble} alt="arrow" className="bubble-img-image testmi-bubble" />
          <img src={book} alt="arrow" className="grp-img-image testmi-badge" />
          <img src={youtubeCoachmi} alt="arrow" className="utube-img-image testmi-youtube" />
          <img src={messageCoachmi} alt="arrow" className="message-img-image testmi-msg" />
        </div>
      </div>
      </div>
    </>
  );
};
export default CoachMI_Banner;
