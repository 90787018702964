import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import isolation from "../asset/images/Isolation_Mode.png";
import partnerLogo from "../asset/images/Frame 1261157589.png";
import "./style/institution-partner-Program.scss";
import mobileinstitute from "../asset/images/mobileinstitute.png";
import popupImage from "../asset/images/popupImage.png";
import closeIcon from "../asset/images/Close_round_fill.png";
import { institutioncontactregister } from "./reducers/WebsiteSlice";
import { Button, Modal, Form, Input,message } from "antd";
import * as yup from "yup";
import { Formik, useFormik } from "formik";
import { Navigate } from "react-router-dom";

const Institution_partner_Program = () => {
  const initialValues = {
    name: "",
    colleageName: "",
    mobileNumber: "",
    email: "",
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    colleageName: yup.string().required("College/School name is required"),
    mobileNumber: yup
      .string()
      .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits")
      .required("Mobile number is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setIsModalOpen(false);
      dispatch(institutioncontactregister(values)).unwrap().then((res) =>{
        if(res){
          message.success(res.message)
          formik.resetForm()
        }
      });
    },
  });

  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [btntitle, setBtnTitle] = useState("");

  const dispatch = useDispatch();

  // const handleSubmit = async (event) => {
  //   setBtnTitle("Submit");
  //   event.preventDefault();
  //   // Check if any field is empty
  //   const formErrors = {};
  //   Object.keys(formData).forEach((key) => {
  //     if (!formData[key]) {
  //       formErrors[key] = `${
  //         key.charAt(0).toUpperCase() + key.slice(1)
  //       } is required`;
  //     }
  //   });

  //   // If any field is empty, set errors and prevent form submission
  //   if (Object.keys(formErrors).length > 0) {
  //     setErrors(formErrors);
  //     return;
  //   }

  //   // Dispatch form data if no errors
  //   try {
  //     dispatch(institutioncontactregister(formData));
  //     console.log("Form Data:", formData);
  //     setIsSubmit(true);
  //     setIsOpen(false);
  //   } catch (error) {
  //     console.error("Error submitting form:", error);
  //   }
  // };

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const partnersData = [
    { id: 1, logoSrc: "../asset/images/Frame 1261157589.png" },
    { id: 2, logoSrc: "../asset/images/Frame 1261157589.png" },
    { id: 3, logoSrc: "../asset/images/Frame 1261157589.png" },
    // { id: 4, logoSrc: '../asset/images/Frame 1261157589.png' }
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = (e) => {
    setBtnTitle("Submit");
    setIsModalOpen(true);
    document.body.style.overflow = 'hidden';

  };

  const handleCancel = () => {
    formik.resetForm();
    document.body.style.overflow = 'unset';
    setIsModalOpen(false);
  };
  const learnMore = () => {
    window.location.href = '/institutionPartner';
  }

  return (
    <>
      <div className="Institution_partner_Program">
      <div className="wrapper-container">

        <div className="innerContainer">
          <div className="institutioncontent">
            <img src={isolation} alt="logo-img" className="logo-img" />
            <div className="intitution-image">
              <p className="text">
                An exclusive partnership program with schools or colleges and
                coaching institutions Transform your institution into the
                ultimate hub.
              </p>
              <div className="button">
                <button className="btn1" onClick={showModal}>
                  Register Now
                </button>
                <button className="btn2" onClick={learnMore}>Learn More</button>
                {/* <div className="button">
                         <a href="/institutionPartner" className="btn2" >Register</a>
                        </div> */}

                {/* {isOpen && <></>} */}
                <Modal  open={isModalOpen} footer={null} onCancel={handleCancel}>
                  {/* <div className="popup"> */}
                  <div className="popup-content" style={{ display: "flex" }}>
                    <div className="popup-left">
                      <img src={popupImage} alt="placeholder" />
                    </div>
                    <div className="popup-right">
                      <p className="register" style={{ textAlign: "center" }}>
                        Register
                      </p>
                      <Formik
                        validateOnChange={false}
                        validateOnBlur={false}
                        style={{ gap: "0px" }}
                      >
                        <Form onSubmit={formik.handleSubmit}>
                          <div className="formgroup">
                            <label htmlFor="name">
                            Your Name<span style={{ color: "red" }}> *</span>
                            </label>
                            <div className="input-fields-group">
                              <Input
                                type="text"
                                id="name"
                                placeholder="Enter Your Name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                              />
                              {formik.touched.name && formik.errors.name ? (
                                <div
                                  className="errorMessage"
                                  style={{ color: "red" }}
                                >
                                  {formik.errors.name}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="formgroup">
                            <label htmlFor="colleageName">
                              College/School Name
                              <span style={{ color: "red" }}> *</span>
                            </label>
                            <div className="input-fields-group">
                              <Input
                                type="text"
                                id="colleageName"
                                placeholder="Enter Your College/School Name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.colleageName}
                              />
                              {formik.touched.colleageName &&
                              formik.errors.colleageName ? (
                                <div
                                  className="errorMessage"
                                  style={{ color: "red" }}
                                >
                                  {formik.errors.colleageName}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="formgroup">
                            <label htmlFor="mobileNumber">
                              Mobile Number
                              <span style={{ color: "red" }}> *</span>
                            </label>
                            <div className="input-fields-group">
                              <Input
                                type="text"
                                id="mobileNumber"
                                placeholder="Enter Your Mobile Number"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.mobileNumber}
                              />
                              {formik.touched.mobileNumber &&
                              formik.errors.mobileNumber ? (
                                <div
                                  className="errorMessage"
                                  style={{ color: "red" }}
                                >
                                  {formik.errors.mobileNumber}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="formgroup">
                            <label htmlFor="email">
                            Email ID
                              <span style={{ color: "red" }}> *</span>
                            </label>
                            <div className="input-fields-group">
                              <Input
                                type="email"
                                id="email"
                                placeholder="Enter Your Email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                              />
                              {formik.touched.email && formik.errors.email ? (
                                <div
                                  className="errorMessage"
                                  style={{ color: "red" }}
                                >
                                  {formik.errors.email}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <button type="text" onClick={formik.handleSubmit}>
                            Submit
                          </button>
                        </Form>
                      </Formik>
                    </div>
                  </div>
                  {/* </div> */}
                </Modal>
              </div>
            </div>
            <div className="partner">
              <div className="heading">Our Prominent Partner</div>
              <div className="text">
                Discover the simplicity of success with our top partner
              </div>
              <div className="partner">
                {partnersData.map((partner) => (
                  <img
                    key={partner.id}
                    src={partnerLogo}
                    alt="logo-img"
                    className="partnerlogo-img"
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="mobileView">
            <img src={mobileinstitute} alt="" />
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default Institution_partner_Program;
