import React, { useState,useEffect } from 'react';
import { Card,Button } from 'antd';
import Slider from "react-slick";
import tnpscImage from '../asset/images/tnpscImage.png';
import sscImage from '../asset/images/sscImage.png';
import uccImage from '../asset/images/uccImage.png';
// import profImage from '../asset/images/Rectangle 3465398.png';
import registerFrame from '../asset/images/registerFrame .png';
import youtubeImage from '../asset/images/youtube.png';
import tutorialBackImage from '../asset/images/tutorialbackImage.png'
import { useDispatch, useSelector } from 'react-redux';
import { getTutorial } from '../components/reducers/WebsiteSlice';


const { Meta } = Card;

const Tutorial = () => {


    const { getTutorialData, isLoading, totalDataCount } = useSelector((state) => state.WebsiteSlice);

    const dispatch = useDispatch();

    useEffect(() => {
        console.log("tutorialssss");

        dispatch(getTutorial());
    }, []);

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            // Add more breakpoints as needed
        ],
    };

    return (
        <>
            <div className='tutorial'>
                <div className='sub-tutorial'>
                    <h1>Our Top Tutorial</h1>
                    <p>Discover valuable insights with our top-notch tutorials led by expert instructors.</p>
                </div>
                <div className='tutorial-overall'>


                <div className='tutorial-cards'>
            <Slider {...settings}>
                {getTutorialData.map((video, index) => (
                    <div key={index} className="tutorial-product">
                        <Card
                            hoverable
                            style={{
                                width: 240,
                            }}
                            cover={
                                <video width="350px" height="200px" controls>
                                    <source src={video.videoLink} type="video/mp4" />
                                </video>
                            }
                        >
                            <Meta title={video.name} description={video.examName} />
                        </Card>
                    </div>
                ))}
            </Slider>
        </div>






















                    {/* <div className='tutorial-cards'>
                        <Slider {...settings}>
                            <div className="tutorial-product">
                                <Card
                                    hoverable
                                    style={{
                                        width: 240,
                                    }}

                                    // cover={<video alt="example" src={profImage} />}>
                                    cover={<video width="750" height="500" controls >
      <source src="./Videos/video1.mp4" type="video/mp4"/>
</video>}>
                                    <Meta title="Prof. Mahi" description="Indian Revenue Service (IRS)"/>
                                    
                                </Card> */}
                                {/* <div className='youtube-icon'> */}
                               {/* <img alt="example" src={youtubeImage} className='youtube-image'/> */}
                        {/* </div> */}
                            {/* </div> */}
                            {/* <div className="tutorial-product">
                                <Card
                                    hoverable
                                    style={{
                                        width: 240,
                                    }}
                                    cover={<img alt="example" src={profImage} />}>
                                    <Meta title="Prof. Mahi" description="Indian Revenue Service (IRS)" />
                                    
                                </Card>
                                <img alt="example" src={youtubeImage} className='youtube-image'/>
                            </div>
                            <div className="tutorial-product">
                                <Card
                                    hoverable
                                    style={{
                                        width: 240,
                                    }}
                                    cover={<img alt="example" src={profImage} />}>
                                    <Meta title="Prof. Mahi" description="Indian Revenue Service (IRS)" />
                                    <img alt="example" src={youtubeImage} className='youtube-image'/>
                                    
                                </Card>
                            </div> */}
                           
                        {/* </Slider> */}
                        {/* <div className='youtube-icon'>
                            <img alt="example" src={youtubeImage} className='youtube-image'/>
                        </div> */}
                        {/* <div className='youtube-image-1'>
                            <img alt="example" src={youtubeImage} className='youtube-image '/>
                        </div>
                        <div className='youtube-image-2'>
                            <img alt="example" src={youtubeImage} className='youtube-image '/>
                        </div> */}
                    {/* </div> */}
                </div>
            </div>
        </>
    );
}
export default Tutorial;