import React, { useState, useEffect } from 'react';
import { Layout, Breadcrumb, Flex, Button } from 'antd';
import { Input } from 'antd';
import { UploadOutlined, UserOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Menu, theme } from 'antd';
// import 'antd/dist/antd.css'; // Import the Ant Design styles
import Logo from '../asset/images/Group.png';
import { Carousel, Card,Modal } from 'antd';
import Slider from "react-slick";
import Product from './product';
import Header from './header';
import Unlock from './unlock';
import Keyfeatures from './keyfeatures';
import InstitutionProgram from './institutionprogram';
import CentumPage from './centumpage';
import Community from './community';
import Tutorial from './tutorial';
import BestTeacher from './bestteachers';
import Testomonials from './testimonials';
import Footer from './footer'
import Responsiveheaders from './Responsiveheadertest'
import Banner_Community from './banner-community'
import Beneficialpoints_community from './beneficialpoints-community'
import CentumApp_Community from './centumapp-community'
import Ourcommunity_pricing from './ourcommunity-pricing'
import Count_community from './count-community'
import Infobanner_community from './infobanner-community';
import Testimonials_community from './testimonials-community';
import Footer_community from './footer-community';
import Institution_partner_Program  from './institution-partner-Program';
import Login_container_landing from './login-container-landing';
import Categories_count_landing from './categories-count-landing';
import Banner_Advertisement from './banner-advertisement';
import Why_Centum_Landing from './whycentum-landing';
import Landing_page_best_teacher from './landing-Page-best-teacher';
import Keyfeature_landing from './keyfeature-landing';
import Emppower_landing from './emppower-landing';
import Join_centumapp from './join-centumapp';
import Landing_our_top_tutorial from './landing-our-top-tutorial';
import Advertisement from '../asset/images/adver.png'
import './style/website.scss'
import {CloseOutlined } from '@ant-design/icons';
import closeIcon from '../asset/images/Subtract.png'
import Tutorials from './new-tutorials';
const { Meta } = Card;
const contentStyle = {
    margin: 0,
    height: '160px',
    color: '#fff',
    // lineHeight: '160px',
    // textAlign: 'center',
    // background: '#364d79',
};
 
// const { Header, Content, Footer, Sider } = Layout;
 
const Website = () => {
 
 
    const onChange = (currentSlide) => {
        console.log(currentSlide);
    };
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
 
    const toggleMobileMenu = () => {
        const menuList = document.querySelector('.menu-list');
        menuList.classList.toggle('show-mobile-menu');
    };
 
    const items = [
        {
            label: 'Community',
            key: 'community'
        },
        {
            label: 'TestMI',
            key: 'testmi'
        },
        {
            label: 'CoachMI',
            key: 'coachmi'
        },
        {
            label: 'Exams',
            key: 'exams'
        },
        {
            label: 'Study Material',
            key: 'studymaterial'
        },
        {
            label: 'Success Story',
            key: 'successstory'
        },
        {
            label: 'About us',
            key: 'aboutus'
        }
 
    ];
    const [advertisementelementExists, setAdvertisementElementExists] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    useEffect(() => {
        const element = document.querySelector('.body-content');
    
        const timeoutId = setTimeout(() => {
            if (element) {
                document.body.style.overflow = 'hidden';
                setAdvertisementElementExists(true);
                openModal();
            }
        }, 3000);
    
        return () => {
            clearTimeout(timeoutId);
        };
    }, []);
    
    useEffect(() => {
        if (advertisementelementExists) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [advertisementelementExists]); 
    
    const openModal = () => {
        setModalVisible(true);
    };
    
    const closeModal = () => {
        setModalVisible(false);
        document.body.style.overflow = "auto";
    };
    
    return (
        <>
              {/* <Container > */}
            <div className='centum'>
                <div className='body-content'>
                    <>
                    {advertisementelementExists &&
                    <Modal
                        title=""
                        visible={modalVisible}
                        onCancel={closeModal}
                        footer={null}>
                            <div className='advertisement'>
                                {/* <img src={closeIcon} alt="" className='closeIcon' onClick={closeModal} /> */}
                                <img src={Advertisement}></img>
                            </div>
                    </Modal>
                    }

                    </>
                    <div>
                        < Responsiveheaders />
                    </div>
                    <div>
                        <Banner_Advertisement/>
                    </div>
                     <div>
                        <Login_container_landing/>
                    </div>
                    <div>
                        <Keyfeature_landing/>
                    </div>
                    <div>
                        <Categories_count_landing/>
                    </div>
                    <div>
                        <Product/>
                    </div>
                    <div>
                        <Emppower_landing/>
                    </div>
                    <div>
                        <Institution_partner_Program/>
                    </div>
                    <div>
                        <Join_centumapp/>
                    </div>
                    <div>
                        <Why_Centum_Landing/>
                    </div>
                    <div>
                        <Tutorials/>
                    </div>
                    <div>
                        <Landing_page_best_teacher/>
                    </div>
 
                    <div>
                        <Testimonials_community/>
                    </div>
                    <div>
                        <Footer_community/>
                    </div>
                   
             
                </div>
                
 
 
            </div>

 
        </>
    );
}
 
 
export default Website;