import React, { useState, useEffect,useRef } from 'react';
import { Carousel, Card } from 'antd';
import Responsiveheaders from './Responsiveheadertest';
import Institutiuon_Banner from './institution-banner';
import Institutiuon_Beneficiaries from './institution-beneficials';
import Footer_community from './footer-community';
import Institutiuon_ProgramPlan from './programPlan';
import Institutiuon_Associate from './institution-associate'
import Institutiuon_Government from './institution-govermentExam'
const { Meta } = Card;
const contentStyle = {
  margin: 0,
  height: '160px',
  color: '#fff',

};


const InstitutionPartner = () => {


  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    const menuList = document.querySelector('.menu-list');
    menuList.classList.toggle('show-mobile-menu');
  };
  const opportunityRef = useRef(null);

  const scrollToOpportunity = () => {
    opportunityRef.current.scrollIntoView({ behavior: 'smooth' });
  };



  return (
    <>


      <div className='centum'>

        <div className='body-content'>

          <div>
            < Responsiveheaders />
          </div>

          <div>
            <Institutiuon_Banner institutionContactNowClick={scrollToOpportunity} />
          </div>
          <div ref={opportunityRef}>
            <Institutiuon_Beneficiaries  />
          </div>
          <div>
            <Institutiuon_ProgramPlan />
          </div>
          <div>

            <Institutiuon_Associate />
          </div>
          <div>
            <Institutiuon_Government institutionContactNowClick={scrollToOpportunity} />
          </div>
          <div>
            <Footer_community />
          </div>
     </div>

      </div>
    </>
  );
}


export default InstitutionPartner;