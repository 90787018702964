import React, { useState, useEffect ,useRef} from 'react';
import { Carousel, Card } from 'antd';
import Responsiveheaders from './Responsiveheadertest';
import CoachMI_Banner from './coachMi-banner';
import Footer_community from './footer-community';
import Explore_More from './explore-more';
import Testimonials_community from './testimonials-community';
import CoachMI_Feature from './coachMi-features';
import TopFeature_Coachmi from './topcoachmi-features';
import CoachMi_Learning from './coachmi-startlearning';
const { Meta } = Card;
const contentStyle = {
  margin: 0,
  height: '160px',
  color: '#fff',

};


const CoachMi = () => {


  const opportunityRef = useRef(null);

  const scrollToOpportunity = () => {
    opportunityRef.current.scrollIntoView({ behavior: 'smooth' });
  };


  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    const menuList = document.querySelector('.menu-list');
    menuList.classList.toggle('show-mobile-menu');
  };




  return (
    <>


      <div className='centum'>

        <div className='body-content'>

          <div>
            < Responsiveheaders />
          </div>

          <div >
            <CoachMI_Banner CentumcoachimiRoute={scrollToOpportunity} />
          </div>
          <div >
            <TopFeature_Coachmi />
          </div>
          <div ref={opportunityRef}>
            <CoachMi_Learning />
          </div>
          <div>

            <CoachMI_Feature />
          </div>
          <div>
            <Testimonials_community />
          </div>
          <div>
            <Explore_More />
          </div>
          <div>
            <Footer_community />
          </div>



        </div>

      </div>











    </>
  );
}


export default CoachMi;