import React, { useEffect, useState, useRef } from "react";
import "./style/programPlan.scss";
import { Timeline } from 'antd';
import programPlanImg from '../asset/images/programplanIge.png';


const Institutiuon_ProgramPlan = () => {
   


  return (
    <>
    
<div className="programplan-grid">
<div className="wrapper-container">

    <div className="programplan-image">
      <img src={programPlanImg} alt='programPlanImg' className="programPlanImg" />
        
    </div>
    <div className="programPlan-content">
        <div className="programPlan-content-para">
            <h2>Program Plan</h2>
            <p>Our tailor-made strategy caters specifically to college students, designed to guide you through each step effortlessly.</p>
        </div>
        <div className="programPlan-timeline">
            <Timeline
                items={[
                    {
                        id: 1,
                        label: 'College Campus',
                        dot: (
                            <div className="timeline-number">1</div>
                          ),
                        children: 'Giving free guidance to college students through offline seminars.',
                    },
                    {
                        id: 2,
                        label: 'Exam Screening',
                        dot: (
                            <div className="timeline-number">2</div>
                          ),
                        children: 'Identifying students with a knack for Government competitive exams through CentumAPP Tests.',
                    },
                    {
                        id: 3,
                        label: 'CentumAPP Partnership',
                        dot: (
                            <div className="timeline-number">3</div>
                          ),
                        children: 'Partnering with colleges to have Institutional logins with CentumAPP',
                    },
                ]}
            />
        </div>
        
    </div>
    </div>
</div>

    </>
  );
};
export default Institutiuon_ProgramPlan;
