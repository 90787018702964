import React, { useState, useEffect } from 'react';
import { Carousel, Card } from 'antd';
import { Menu, theme } from 'antd';
import Logo from '../asset/images/Group.png';
import { Layout, Breadcrumb, Flex, Button } from 'antd';
import Advertisement from '../asset/images/adver.png'
import closeIcon from '../asset/images/Subtract.png';
import Explore_More from './explore-more';
import AboutHeader from '../components/aboutus-header';
import Responsiveheaders from './Responsiveheadertest';
import AboutManagement from './about-management';
import AboutMission from './aboutus-mission';
import AboutMembers from './aboutus-members';
import Footer_community from './footer-community';

const { Meta } = Card;
const contentStyle = {
    margin: 0,
    height: '160px',
    color: '#fff',
  
};
 
 
const AboutUs = () => {
 
 
    const onChange = (currentSlide) => {
        console.log(currentSlide);
    };
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        const menuList = document.querySelector('.menu-list');
        menuList.classList.toggle('show-mobile-menu');
    };
    
    
 
  
    return (
        <>

                  
            <div className='centum'>
              
              <div className='body-content'>

                    <div>
                        < Responsiveheaders />
                    </div>
                
                  <div>
                      <AboutHeader />
                  </div>
                  <div>

                  <AboutManagement />
                  </div>
                  <div>
                    <AboutMission />
                  </div>
                  <div>
                    <AboutMembers />
                  </div>
                  <div>
                        <Footer_community/>
                    </div>
               
                 
       
              </div>

          </div>
                  
                   
               
                

 
 
            
 
        
 
        </>
    );
}
 
 
export default AboutUs;