import React, { useEffect, useState, useRef } from "react";
// import './style/testmi-banner.scss';
import "./style/coachMi-banner.scss";
import rocket from "../asset/images/rocketweb.png";
import rocketmobile from "../asset/images/Frame rocket.svg";
import arrow from "../asset/images/testmibannerarrow.png";
import book from "../asset/images/testmibannerbook.png";
import bubble from "../asset/images/tetmibubble.png";
import youtubeCoachmi from "../asset/images/Frame youtube.svg";
import messageCoachmi from "../asset/images/Frame message.svg";
import coachmiStaff from "../asset/images/image7.png";

const CoachMI_Banner = ({CentumcoachimiRoute}) => {
  const [textIndex, setTextIndex] = useState(0);

  const textArray = [
    "Level up your exam game with  <span style='color:#2D81F7;'>CoachMI</span>- your go-to for success!",
    "Get expert support from <span style='color: #2D81F7;'>CoachMI</span>  educators for your success. ",
    "Access  <span style='color: #2D81F7;'>CoachMI's</span>  study materials, tests, and tools for confidence and understanding. ",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % textArray.length);
    }, 700);

    return () => clearInterval(interval);
  }, []);


  const coachiMiExploreClick =()=>{
    if(CentumcoachimiRoute){
      CentumcoachimiRoute();
    }

  }
  return (
    <>
      <div className="banner-headercontain-section">
        <div className="wrapper-container">
        <div className="banner-container-section">
          <div
            className="banner-text-section coachmi-banner-text"
            dangerouslySetInnerHTML={{ __html: textArray[textIndex] }}
          />

          <div className="banner-btn-section">
            <button className="ban-btn-clr-section" onClick={coachiMiExploreClick}>Explore </button>
            <img
              src={rocketmobile}
              alt="rocket"
              className="rocketMobile-image-section paper-rocket-coachmi"
            />
          </div>
        </div>

        <div className="banner-image-section">
          <img src={rocket} alt="rocket" className="rocketWeb-image" />
          <img src={arrow} alt="arrow" className="arrow-img-section arrow-coachmi-section" />
        </div>

        <div className="banner-right-section">
          <img src={coachmiStaff} alt="arrow" className="second-img-imgage coachmi-staff-img" />
          <img src={bubble} alt="arrow" className="bubble-img-image coachmi-bubble" />
          <img src={book} alt="arrow" className="grp-img-image coachmi-badge" />
          <img src={youtubeCoachmi} alt="arrow" className="utube-img-image youtube-coachmi" />
          <img src={messageCoachmi} alt="arrow" className="message-img-image coachmi-msg" />
        </div>
      </div>
      </div>
    </>
  );
};
export default CoachMI_Banner;

// import React, { useEffect, useState, useRef } from "react";
// // import './style/testmi-banner.scss';
// import "./style/coachMi-banner.scss";
// import rocket from "../asset/images/rocketweb.png";
// import rocketmobile from "../asset/images/Frame rocket.svg";
// import arrow from "../asset/images/testmibannerarrow.png";
// import book from "../asset/images/testmibannerbook.png";
// import bubble from "../asset/images/tetmibubble.png";
// import coachMiTeacher from "../asset/images/image7.png";
// import youtubeCoachmi from "../asset/images/Frame youtube.svg";
// import messageCoachmi from "../asset/images/Frame message.svg";

// const CoachMI_Banner = () => {
//   const [textIndex, setTextIndex] = useState(0);

//   const textArray = [
//     "Level up your exam game with  <span style='color:#2D81F7;'>CoachMI</span>- your go-to for success!",
//     "Get expert support from <span style='color: #2D81F7;'>CoachMI</span>  educators for your success. ",
//     "Access  <span style='color: #2D81F7;'>CoachMI's</span>  study materials, tests, and tools for confidence and understanding. ",
//   ];

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setTextIndex((prevIndex) => (prevIndex + 1) % textArray.length);
//     }, 700);

//     return () => clearInterval(interval);
//   }, []);

//   return (
//     <>
//       <div className="coachiMi-banner-headercontain">
//         <div className="coachiMi-banner-container">
//           <div
//             className="coachMi-banner-text"
//             dangerouslySetInnerHTML={{ __html: textArray[textIndex] }}
//           />

//           <div className="coachMi-banner-btn">
//             <button className="coachMi-ban-btn-clr">Explore </button>
//             <img
//               src={rocketmobile}
//               alt="rocket"
//               className="coachMi-rocketMobile"
//             />
//           </div>
//         </div>

//         <div className="coachiMi-banner-image">
//           <img src={rocket} alt="rocket" className="coachMi-rocketWeb" />
//           <img src={arrow} alt="arrow" className="coachMi-arrow-img" />
//         </div>

//         <div className="coachMi-banner-right ">
//           <img
//             src={coachMiTeacher}
//             alt="arrow"
//             className="coachMi-second-img"
//           />
//           <img src={bubble} alt="arrow" className="coachMi-bubble-img" />
//           <img src={book} alt="arrow" className="coachMi-grp-img" />
//           <img src={youtubeCoachmi} alt="arrow" className="coachMi-utube-img" />
//           <img
//             src={messageCoachmi}
//             alt="arrow"
//             className="coachMi-message-img"
//           />
//         </div>
//       </div>
//     </>
//   );
// };
// export default CoachMI_Banner;
