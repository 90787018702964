import React, { useState, useEffect, useRef } from 'react';
import '../components/style/termscondition-content.scss';

const TermsCondition_Content = () => {

    return (
        <>


            <div class="terms-condition-page-container">
            <div className='wrapper-container'>
                <div>
                    <h1 className='terms-condition-header'>Terms and conditions</h1>
                    <p className='terms-condition-paragraph'>Last updated: January 04, 2024</p>
                    <p className='terms-condition-paragraph'>The Website Owner, including subsidiaries and affiliates
                        (“Website” or “Website Owner” or “we” or “us” or “our”)
                        provides the information contained on the website or
                        any of the pages comprising the website (“website”) to
                        visitors (“visitors”) (cumulatively referred to as “you” or
                        “your” hereinafter) subject to the terms and conditions
                        set out in these website terms and conditions, the
                        privacy policy and any other relevant terms and
                        conditions, policies and notices which may be applicable
                        to a specific section or module of the website.
                    </p>
                    <p className='terms-condition-paragraph'>Welcome to our website. If you continue to browse and
                        use this website you are agreeing to comply with and be
                        bound by the following terms and conditions of use,
                        which together with our privacy policy govern CENTUM
                        EDUTECH PRIVATE LIMITED''s relationship with you in
                        relation to this website.</p>
                    <p className='terms-condition-paragraph'>The term 'CENTUM EDUTECH PRIVATE LIMITED' or
                        'us' or 'we' refers to the owner of the website whose
                        registered/operational office is 170/4, STR
                        KEELAKAVATHAGUDI NANNAILAM, 3TH STREET,
                        CHITHAMBARAM NAGAR, Senganur Thiruvarur
                        TAMIL NADU 610107. The term 'you' refers to the user
                        or viewer of our website.</p>
                </div>
                <div>

                    <h1 className='terms-condition-header'>The use of this website is subject to the following terms
                        of use:</h1>



                    <p className='terms-condition-paragraph'>The content of the pages of this website is for
                        your general information and use only. It is
                        subject to change without notice.</p>

                    <p className='terms-condition-paragraph'>Neither we nor any third parties provide any
                        warranty or guarantee as to the accuracy,
                        timeliness, performance, completeness or
                        suitability of the information and materials found
                        or offered on this website for any particular
                        purpose. You acknowledge that such information
                        and materials may contain inaccuracies or errors
                        and we expressly exclude liability for any such
                        inaccuracies or errors to the fullest extent
                        permitted by law.
                    </p>
                    <ul>
                        <li>
                            <p className='terms-condition-paragraph'>Your use of any information or materials on this
                                website is entirely at your own risk, for which we
                                shall not be liable. It shall be your own
                                responsibility to ensure that any products,
                                services or information available through this
                                website meet your specific requirements.</p>
                        </li>
                        <li>
                            <p className='terms-condition-paragraph'>This website contains material which is owned by
                                or licensed to us. This material includes, but is
                                not limited to, the design, layout, look,
                                appearance and graphics. Reproduction is
                                prohibited other than in accordance with the
                                copyright notice, which forms part of these terms
                                and conditions.</p>
                        </li>
                        <li>
                            <p className='terms-condition-paragraph'>All trademarks reproduced in this website which
                                are not the property of, or licensed to, the
                                operator are acknowledged on the website.</p>
                        </li>
                        <li>
                            <p className='terms-condition-paragraph'>Unauthorized use of this website may give rise to
                                a claim for damages and/or be a criminal offense.</p>
                        </li>
                        <li>
                            <p className='terms-condition-paragraph'>From time to time this website may also include
                                links to other websites. These links are provided
                                for your convenience to provide further
                                information.</p>
                        </li>
                        <li>
                            <p className='terms-condition-paragraph'>You may not create a link to this website from
                                another website or document without CENTUM
                                EDUTECH PRIVATE LIMITED’s prior written
                                consent.</p>
                        </li>
                        <li>
                            <p className='terms-condition-paragraph'>Your use of this website and any dispute arising
                                out of such use of the website is subject to the
                                laws of India or other regulatory authority.</p>
                        </li>
                        <li>
                            <p className='terms-condition-paragraph'>We as a merchant shall be under no liability whatsoever
                                in respect of any loss or damage arising directly or
                                indirectly out of the decline of authorization for any
                                Transaction, on Account of the Cardholder having
                                exceeded the preset limit mutually agreed by us with our
                                acquiring bank from time to time
                            </p>
                        </li>
                    </ul>
                    <h2 className='terms-sub-header'>Disclaimer:</h2>
                    <p className='terms-condition-paragraph'>  The above content is created at CENTUM
                        EDUTECH PRIVATE LIMITED's sole discretion.
                        Razorpay shall not be liable for any content provided
                        here and shall not be responsible for any claims and
                        liability that may arise due to merchant’s non-adherence
                        to it.
                    </p>

                </div>

            </div>
</div>



        </>
    );
}
export default TermsCondition_Content;