import React, { useState, useEffect } from 'react';
import { Carousel, Card } from 'antd';
import Responsiveheaders from './Responsiveheadertest';
import Footer_community from './footer-community';
import PrivacyHeader from './privacypolicy-header';
import PrivacyContent from './privacypolicy-content'

const { Meta } = Card;
const contentStyle = {
    margin: 0,
    height: '160px',
    color: '#fff',
  
};
 
 
const PrivacyPolicy = () => {
 
 
    const onChange = (currentSlide) => {
        console.log(currentSlide);
    };
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        const menuList = document.querySelector('.menu-list');
        menuList.classList.toggle('show-mobile-menu');
    };
    
    
 
  
    return (
        <>

                  
            <div className='centum'>
              
              <div className='body-content'>

                    <div>
                        < Responsiveheaders />
                    </div>
                    <div>
                        <PrivacyHeader />
                    </div>
                    <div>
                        <PrivacyContent />
                    </div>
                  <div>
                        <Footer_community/>
                    </div>
               
                 
       
              </div>

          </div>
                  
                   
               
                

 
 
            
 
        
 
        </>
    );
}
 
 
export default PrivacyPolicy;