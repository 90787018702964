import React, { useEffect, useState, useRef } from "react";
import './style/hubSchool-benefit.scss';



const Benefits_HUbschool = () => {
   




  return (
    <>

    <div className="benefits-hubcontainer">
    <div className="wrapper-container">

        <div className="benefits-para-container">
            <p>Benefits of Hub School</p>
        </div>
        <div className="benefits-hubswhole-card">
            <div className="benefits-hub-subcard">
                <div className="benefits-card-container">
                    <div className="benefits-index-number">
                        <p>01</p>
                    </div>
                    <div className="benefits-textpara-card">
                        <p className="benefits-head-paracard">Expert Faculty</p>
                        <p className="benefits-para-card">Well experienced NEET & IIT Faculties from Tamil Nadu and other States.</p>
                    </div>
                </div>
                <div className="benefits-card-container">
                <div className="benefits-index-number">
                    <p>02</p>
                    </div>
                    <div className="benefits-textpara-card">
                        <p className="benefits-head-paracard">NEET Workshops</p>
                        <p className="benefits-para-card">Exclusive offline NEET Classes</p>
                    </div>
                </div>
            </div>
            <div className="benefits-hub-subcard">
                <div className="benefits-card-container">
                    <div className="benefits-index-number">
                        <p>03</p>
                    </div>
                    <div className="benefits-textpara-card">
                        <p className="benefits-head-paracard">Comprehensive Testing</p>
                        <p className="benefits-para-card">Online Practice Test - Chapter wise, Subject Wise, Full syllabus.</p>
                    </div>
                </div>
                <div className="benefits-card-container">
                <div className="benefits-index-number">
                    <p>04</p>
                    </div>
                    <div className="benefits-textpara-card">
                        <p className="benefits-head-paracard">Staff Focus</p>
                        <p className="benefits-para-card">Exclusive Staff-Only Doubt Clearing Session</p>
                    </div>
                </div>
            </div>
        </div>
</div>
    </div>

    </>
  );
};
export default Benefits_HUbschool;
