import React, { useState } from 'react';

import './style/hubschool-partner.scss';

import  orangeStar from '../asset/images/orangeStar.png';
import  orangeBigCircle from '../asset/images/orangeBigCircle.png';
import  orangeDotImg from '../asset/images/orangeDotImg.png';
import  orangeEclipse from '../asset/images/orangeEclipse.png';
import frachiseJoinImg from '../asset/images/frachiseJoinImg.png';
// import mblOrangeTop from '../asset/images/mblOrangeTop.png';
import mblorangebottom from '../asset/images/mblorangebottom.png';
import mblOrangeTop from '../asset/images/mblOrangeTop.png';
import mblrightImg from '../asset/images/mblrightImg.png';



const Frenchise_Partner = ({joinContactNowClick}) => {


    const handleJoinContactClick = () => {
        if (joinContactNowClick) {
          joinContactNowClick();
        }
      };

    return (
        <>  
                <div className="container-government hubschl-partner franchise-partner">
    <div className="wrapper-container">

                    <div className="government-content-btn hubschool-content-btn">
                        <p className="government-content-para franchise-content-para">
                        Join our franchise, be part of our success story.
                        </p>
                        <div className="government-button hubschool-button">
                         <a className="government-btn"  onClick={handleJoinContactClick}>Contact Now </a>
                        </div>
                <img src={orangeEclipse} alt='orangeEclipse' className='orangeEclipse-image'/>
                <img src={orangeBigCircle} alt='orangeBigCircle' className='orangeBigCircle'/>
                 
                    </div>
                <img src={orangeStar} alt='orangeStar' className='orangeStar-image' />
                <img src={orangeStar} alt='orangeStar' className='orangeStar-image1'/>

                <img src={orangeDotImg} alt='orangeDotImg' className='government-dots-Image hubs-dots-Image franchise-dots-Image'/>
                  
                    <div className='second-governmentImage'>
                <img src={frachiseJoinImg} alt='governmentImage' className='government-Image hubs-boy-image franchise-boy-image'/>
                     
                <img src={mblorangebottom} alt='mblorangebottom' className='mblorangebottom-image'/>
                <img src={mblOrangeTop} alt='mblOrangeTop' className='mblOrangeTop'/>
                <img src={mblrightImg} alt='mblrightImg' className='mblrightImg-image'/>


                </div>
                </div>
                </div>
               
        </>
    )
}
export default Frenchise_Partner;