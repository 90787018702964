import React, { useEffect, useState, useRef } from 'react';
import './style/coachMi-features.scss';
import featuresStar from '../asset/images/whitestar.png'





const CoachMI_Feature = () => {

    return (
        <>
            <div className='coachMi-features-container'>
                <div className='wrapper-container'>
                <div>

                    <img src={featuresStar} alt='logo' className="coachmi-features-img" />
                </div>
                <div className='coachMi-division'>
                    <div className='coachMi-features-content'>
                        <p>Features of CoachMI</p>
                    </div>
                    <div className='coachMi-features-lists-container'>
                        <div className='coachMi-features-list'>
                            <ul>
                                <li>100% syllabus coverage of all exams.</li>
                                <li>Free, Live and recorded classes.</li>
                                <li>Systematic study plan Exam, Unit and Topic wise</li>
                            </ul>
                        </div>
                        <div className='coachMi-features-list'>
                            <ul>
                                <li>Free study material for each video</li>
                                <li>Self study evaluation </li>
                                <li>Free webinars & motivation talks by experts </li>
                            </ul>
                        </div>
                        <div className='coachMi-features-list'>
                            <ul>
                                <li>AI based performance analysis​</li>
                                <li>Weekly, monthly Current Affairs coverage for all exams</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
           </div>
        </>
    )
}
export default CoachMI_Feature;