import React, { useState } from 'react';
import mt1 from './../asset/images/speech 1.png';
import mt2 from './../asset/images/news 1.png';
import mt3 from './../asset/images/exam 1.png';
import mt4 from './../asset/images/pdf 1.png';
import mt5 from './../asset/images/favourite 1.png';
import mt6 from './../asset/images/ranking 1.png';
import mt7 from './../asset/images/fire 1.png';
import mt8 from './../asset/images/web-analytics 1.png';
import mt9 from './../asset/images/notebook 1.png';
import mt10 from './../asset/images/webminar 1.png';
import mt11 from './../asset/images/notification 1.png';
import mt12 from './../asset/images/key 1.png';
import main from './../asset/images/Frame 1261157865.svg';
import title from './../asset/images/centum.png';
import comunityGif from '../asset/images/communityGif-image.gif';
import groupTab from '../asset/images/Group_tab.png'
import './style/benificialpoints-community.scss';
const Beneficialpoints_community = () => {
    const handleJoinNowClick = () => {
        const centumAppSection = document.getElementById('centumapp_community');
        if (centumAppSection) {
            centumAppSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (

            <>
    <div className="hole_beneficial_points">
    <div className="wrapper-container">

        <div className="new_beneficial_points">
            <div className='main_beneficial_points'>
                <div className='firsthalf_beneficial_points'>
                    <div className='title1_beneficial_points'>
                        Join our community to get below beneficial points
                        {/* <div className="beneficial_points_beneficial_points">
                            
                        </div> */}
                    </div>
                    <div className='title2_beneficial_points'>
                        <img src={main} alt='logo' className="logo-img_beneficial_points" />
                        900+ Active community members
                    </div>
                    <div className="beneficial_points_beneficial_points">
                        <button className='btn1_beneficial_points' onClick={handleJoinNowClick}>Join Community</button>
                    </div>
                </div>
                <div className='whole_beneficial_points'>
                    <div className='secondhalf_beneficial_points'>
                        <div className='mt1_beneficial_points'>
                            <img src={mt1} alt='logo' className="logo-img_beneficial_points" />
                            <div className="beneficial_points_beneficial_points">
                                Motivational talks    
                            </div>
                        </div>
                        <div className='mt1_beneficial_points'>
                            <img src={mt2} alt='logo' className="logo-img_beneficial_points" />
                            <div className="beneficial_points_beneficial_points">
                                Current affairs 
                            </div>
                        </div>
                        <div className='mt1_beneficial_points'>
                            <img src={mt3} alt='logo' className="logo-img_beneficial_points" />
                            <div className="beneficial_points_beneficial_points">
                                Daily MCQs / Free quizzes  
                            </div>
                        </div>
                        <div className='mt1_beneficial_points'>
                            <img src={mt4} alt='logo' className="logo-img_beneficial_points" />
                            <div className="beneficial_points_beneficial_points">
                                Study PDFs  
                            </div>
                        </div>
                        <div className='mt1_beneficial_points'>
                            <img src={mt5} alt='logo' className="logo-img_beneficial_points" />
                            <div className="beneficial_points_beneficial_points">
                                Value enriching notes
                            </div>
                        </div>
                        <div className='mt1_beneficial_points'>
                            <img src={mt6} alt='logo' className="logo-img_beneficial_points" />
                            <div className="beneficial_points_beneficial_points">
                                Rankings
                            </div>
                        </div>
                    </div>
                    <div className='secondhalf_beneficial_points'>
                        <div className='mt1_beneficial_points'>
                            <img src={mt7} alt='logo' className="logo-img_beneficial_points" />
                            <div className="beneficial_points_beneficial_points">
                                Daily Streaks
                            </div>
                        </div>
                        <div className='mt1_beneficial_points'>
                            <img src={mt8} alt='logo' className="logo-img_beneficial_points" />
                            <div className="beneficial_points_beneficial_points">
                                Feedback and analysis
                            </div>
                        </div>
                        <div className='mt1_beneficial_points'>
                            <img src={mt9} alt='logo' className="logo-img_beneficial_points" />
                            <div className="beneficial_points_beneficial_points">
                                Exam planner 
                            </div>
                        </div>
                        <div className='mt1_beneficial_points'>
                            <img src={mt10} alt='logo' className="logo-img_beneficial_points" />
                            <div className="beneficial_points_beneficial_points">
                                Webinars
                            </div>
                        </div>
                        <div className='mt1_beneficial_points'>
                            <img src={mt11} alt='logo' className="logo-img_beneficial_points" />
                            <div className="beneficial_points_beneficial_points">
                                Job notifications
                            </div>
                        </div>
                        <div className='mt1_beneficial_points'>
                            <img src={mt12} alt='logo' className="logo-img_beneficial_points" />
                            <div className="beneficial_points_beneficial_points">
                                Testmi & Coachmi access
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
            <div className='img_beneficial_points'>
                <img src={comunityGif} alt='logo' className="group-web" />
                <img src={groupTab} alt='logo' className='group-tab'/>
            </div>  
        </div>
    </div>
    </div>
</>

    )
}
export default Beneficialpoints_community;