import React, { useState } from 'react';
import './style/emppower-landing.scss';
import star from './../asset/images/Star 12.png';
import rocket from './../asset/images/rocketImage.png';






const Emppower_landing = () => {

    return (
        <>
            {/* <div>
                hi
            </div> */}

            <div className="overall-container">
            <div className="wrapper-container">

                <div className='image-roc'>
                <img src={rocket}  className='rocket-emppower'/>
                </div>
                <div className='image-roc-res'>
                <img src={rocket}  className='rocket-mob'/>
                </div>
                <div className="container ">
                    <div className="star1">
                        <img src={star} className='img1 blink' style={{ animationDelay: '1s' }} />
                    </div>
                    <div className="star2">
                        <img src={star} className='img2 blink' style={{ animationDelay: '2s' }}/>
                    </div>
                    <div className="star3">
                        <img src={star} className='img3 blink' style={{ animationDelay: '3s' }} />
                    </div>
                    <div className="star4">
                        <img src={star} className='img4 blink' style={{ animationDelay: '4s' }} />
                    </div>

                    <div className="content-btn">
                        <div className="content-para">
                            Empower your learning journey. Register today and unlock limitless education !
                        </div>
                        <div className="button">
                         <a href="https://app.centum.academy/signUp" className="btn" >Register</a>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}
export default Emppower_landing;