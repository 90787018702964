import React, { useState } from 'react';
import './style/whycentum-landing.scss';
import greenstar from '../asset/images/greenstar.png'
import redstar from '../asset/images/redstar.png'
import yellowstar from '../asset/images/yellowstar.png'
import learn from '../asset/images/learn.png'
import detailed from '../asset/images/detail.png'
import liveexam from '../asset/images/liveexam.png'
import multilingual from '../asset/images/multilingual.png'
import bluestar from '../asset/images/bluestar.png'
import boyImage from '../asset/images/bulb_boy.png'
import bulb from '../asset/images/bulb_Why.png'


const Why_Centum_Landing = () => {

    return (
        <>
            <div className='main'>
            <div className='wrapper-container'>

                <div className='whyCentum'>
                    <div className='centum-content'>
                        <h3>Why CentumApp?</h3>
                        <div className='centum-text'>
                        <p>Get access to all our materials and resources by joining the
                            community of thousands of aspirants and get yourself imbibed
                            in the process of learning among peers!
                        </p>
                        </div>
                    </div>
                    <div className='boyImage_bulb'>
                        <div>
                            <img className='boyImage' src={boyImage} alt="" />
                        </div>
                        <div>
                            <img className='bulb_image' src={bulb} alt="" />
                        </div>
                    </div>
                </div>
                <div className='greenYellow'>
                    <img className='green blink' src={greenstar} alt="" />
                    <img className='yellow blink' src={yellowstar} alt="" />
                </div>
                <div className='redBlue'>
                    <img className='red blink' src={redstar} alt="" />
                    <img className='blue blink' src={bluestar} alt="" />
                </div>
                <div className="cardsSection">
                    <div className='cardsDiv1'>
                        <div className='card card1'>
                            <img src={learn} alt="" />
                            <h1>Learn from the best</h1>
                            <p>Learn from the masters of the subject, in the most engaging yet simplified ways.</p>
                        </div>
                        <div className='card card2'>
                            <img src={detailed} alt="" />
                            <h1>Detailed Score Analysis</h1>
                            <p>Get a detailed breakdown of your strength & weakness and discover insight to improve your score.</p>
                        </div>
                    </div>
                    <div className='cardsDiv2'>
                        <div className='card card3'>
                            <img src={liveexam} alt="" />
                            <h1>Live Exam Simulation</h1>
                            <p>Master exams with precision through our Live Exam Simulation – your key to realistic, high-stakes preparation!</p>
                        </div>
                        <div className='card card4'>
                            <img src={multilingual} alt="" />
                            <h1>Multilingual</h1>
                            <p>Enhance your expertise effortlessly across multiple languages for understanding and accelerated learning.</p>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}
export default Why_Centum_Landing;