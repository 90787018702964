import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getproducts } from "./reducers/WebsiteSlice";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from "jquery";
import "slick-carousel/slick/slick.min.js";
import { Card,Button } from "antd";
import './style/our-top-products-landing.scss';
const { Meta } = Card;

const Product = () => {
  const { getproductsData, isLoading, totalCount } = useSelector(
    (state) => state.WebsiteSlice
  );

  const dispatch = useDispatch();

  // useEffect(() => {
  //   slickSliderCarosal()
  // });
  useEffect(() => {
    console.log("getProducts.....", getproductsData);

    dispatch(getproducts())
      .unwrap()
      .then((res) => {
        slickSliderCarosal();
      });
  }, []);

  const slickSliderCarosal = () => {
    $(".my-slider")
      .not(".slick-initialized")
      .slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        dots: true,
        speed: 300,
        infinite: true,
        autoplaySpeed: 3000,
        autoplay: true,
        responsive: [
          {
            breakpoint: 1350,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1030,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1020,
            settings: {
              arrows: false,
              // centerMode: true,
              centerPadding: '40px',
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {

            breakpoint: 991,
            settings: {
              arrows: false,
                slidesToShow: 1,
            }
        },
          {
            breakpoint: 781,
            settings: {
              arrows: false,
              // centerMode: true,
              centerPadding: '40px',
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              autoplay: false,
              // centerMode: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
            },
          },
          {
            breakpoint: 320,
            settings: {
              arrows: false,
              autoplay: false,
              // centerMode: true,
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
  };
  

  const handleExploreClick = (url) => {
    window.location.href = url;
  };

  return (
    <>
      <div className="products-container">
      <div className="wrapper-container">
        <div className="products-header">
          <div className="product-title"> Our Top Products</div>
          <div className="product-description">
            Check out our best products to boost your knowledge!
          </div>
        </div>

        <div class="wrapper">
          <div class="my-slider our-product-slider">
            {getproductsData.map((d, index) => (
              <div style={{background: 'unset'}}>
                <Card
                hoverable
                style={{ width: 240 ,background: '#fff' }}
                cover={<img alt="example" src={d.productImageUrl} />}
              >
                <Meta title={d.title}/>
                <div className="card-btn-wrapper">
                <Button
                  className="card-btn"
                  onClick={() => handleExploreClick(d.exploreLink)}
                >
                  Explore
                </Button>
                </div>
              </Card>
              </div>
            ))}
          </div>
        </div>
        </div>
      </div>
    </>
  );
};

export default Product;

