import React, { useEffect, useState, useRef } from "react";
import'./style/coachMi-banner.scss';
import rocket from "../asset/images/rocketweb.png";
import rocketmobile from "../asset/images/Frame rocket.svg";
import arrow from "../asset/images/testmibannerarrow.png";
import book from "../asset/images/testmibannerbook.png";
import hubbannerImg from "../asset/images/hubbannerImg.png";

const SchoolHub_Banner = ({hubContactNowClick}) => {
  const [textIndex, setTextIndex] = useState(0);

  const textArray = [
    "Unveil Your Potential at the   <span style='color:#2D81F7;'>CentumAPP</span>Hub School!",
    "Explore Your Potential at the  <span style='color: #2D81F7;'>CentumAPP</span> Hub School! ",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % textArray.length);
    }, 700);

    return () => clearInterval(interval);
  }, []);

  const handleHUbContactClick = () => {
    if (hubContactNowClick) {
      hubContactNowClick();
    }
  };

  return (
    <>
      <div className="banner-headercontain-section">
        <div className="wrapper-container">
        <div className="banner-container-section">
          <p className="banner-para">Hub School</p>
          <div
            className="banner-text-section hubschool-text-section"
            dangerouslySetInnerHTML={{ __html: textArray[textIndex] }}
          />

          <div className="banner-btn-section">
            <button className="ban-btn-clr-section schoolHub-contact-btn" onClick={handleHUbContactClick} >Contact Now</button>
          </div>
        </div>

        <div className="banner-image-section">
          {/* <img src={rocket} alt="rocket" className="rocketWeb-image hubRocket-image" /> */}
          <img src={arrow} alt="arrow" className="arrow-img-section hubArrow-img-section" />
        </div>

        <div className="banner-right-section">
          <img src={hubbannerImg} alt="arrow" className="second-img-imgage hubSchool-img-imgage" />
          <img src={book} alt="arrow" className="grp-img-image hubBook-image" /> 
        </div>
      </div>
      </div>
    </>
  );
};
export default SchoolHub_Banner;