import React, { useState, useEffect ,useRef} from 'react';
import { Layout, Breadcrumb, Flex, Button } from 'antd';
import { Input } from 'antd';
import { UploadOutlined, UserOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Menu, theme } from 'antd';
// import 'antd/dist/antd.css'; // Import the Ant Design styles
import Logo from '../asset/images/Group.png';
import { Carousel, Card } from 'antd';
import Slider from "react-slick";
import Product from './ourproduct';
import Header from './header';
import Unlock from './unlock';
import Keyfeatures from './keyfeatures';
import InstitutionProgram from './institutionprogram';
import CentumPage from './centumpage';
import Community from './community';
import Tutorial from './tutorial';
import BestTeacher from './bestteachers';
import Testomonials from './testimonials';
import Footer from './footer'
import Responsiveheaders from './Responsiveheadertest'
import Banner_Community from './banner-community'
import Beneficialpoints_community from './beneficialpoints-community'
import CentumApp_Community from './centumapp-community'
import Ourcommunity_pricing from './ourcommunity-pricing'
import Count_community from './count-community'
import Infobanner_community from './infobanner-community';
import Testimonials_community from './testimonials-community';
import Footer_community from './footer-community';
import Institution_partner_Program  from './institution-partner-Program';
import Login_container_landing from './login-container-landing';

import Explore_More from './explore-more';

const Community_ceuntum = () => {



    const opportunityRef = useRef(null);

    const scrollToOpportunity = () => {
      opportunityRef.current.scrollIntoView({ behavior: 'smooth' });
    };
  
    const onChange = (currentSlide) => {
        console.log(currentSlide);
    };
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        const menuList = document.querySelector('.menu-list');
        menuList.classList.toggle('show-mobile-menu');
    };



 
    return (
        <>

            <div className='centum'>
              
                <div className='body-content'>
                    <div>
                        < Responsiveheaders />
                    </div>
                     <div>
                    < Banner_Community exploreCommunityRoute={scrollToOpportunity}  />
                    </div>
                    <div  ref={opportunityRef}>
                    <Beneficialpoints_community />
                    </div>
                    <div>
                        <CentumApp_Community />
                    </div>
                    <div>
                        <Ourcommunity_pricing />
                    </div>
                    <div>
                        < Count_community/>
                    </div>
                    <div>
                        <Infobanner_community/>
                    </div>
                    <div>
                        <Testimonials_community/>
                    </div> 
                    <div>
                        <Explore_More/>
                    </div>
                    <div>
                        <Footer_community/>
                    </div>
                   
         
                </div>

            </div>

           
            

        </>
    );
}


export default Community_ceuntum;