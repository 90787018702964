import React, { useState, useEffect,useRef } from 'react';
import { Carousel, Card } from 'antd';
import Responsiveheaders from './Responsiveheadertest';
import SchoolsHub_Banner from './hubSchool-banner';
import Hubschool_Beneficiaries from './hubschool-Beneficiaries';
import Benefits_HUbschool from './hubSchool-benefit';
import Hubschool_Whycentum from './hubschool-whycentum';
import Hubschool_Partner from './hubschool-partner';
import Footer_community from './footer-community';

const { Meta } = Card;
const contentStyle = {
  margin: 0,
  height: '160px',
  color: '#fff',

};


const SchoolsHub = () => {

  const opportunityRef = useRef(null);

  const scrollToOpportunity = () => {
    opportunityRef.current.scrollIntoView({ behavior: 'smooth' });
  };


  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    const menuList = document.querySelector('.menu-list');
    menuList.classList.toggle('show-mobile-menu');
  };




  return (
    <>


      <div className='centum'>

        <div className='body-content'>

          <div>
            < Responsiveheaders />
          </div>

          <div>
            <SchoolsHub_Banner hubContactNowClick={scrollToOpportunity} />
          </div>
          <div ref={opportunityRef}> 
            <Hubschool_Beneficiaries />
          </div>
          <div>
            <Benefits_HUbschool />
          </div>
          <div>
            <Hubschool_Whycentum />
          </div>
          <div>
            <Hubschool_Partner hubContactNowClick={scrollToOpportunity} />
          </div> 
          <div>
            <Footer_community />
          </div>
     </div>

      </div>
    </>
  );
}


export default SchoolsHub;