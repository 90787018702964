import React, { useState, useEffect, useRef } from 'react';
import '../components/style/privacypolicy-header.scss';
import privacyImage from "../asset/images/privacy 1.png"


const PrivacyHeader = () => {

    return (
        <>


            <div className='privacy-policy-container'>
            <div className='wrapper-container'>

                <div className='privacy-policy-header'>
                    <p>Home  | <span>Privacy Policy</span> </p>
                    <h2>Privacy Policy</h2>

                </div>
                <div className='privacy-sub-header'>
                    <img src={privacyImage} alt='Keystar' className="" />


                </div>
            </div>
            </div>



        </>
    );
}
export default PrivacyHeader;