import React, { useEffect, useState, useRef } from "react";
// import'./style/coachMi-banner.scss';
import './style/franchise-banner.scss'

const Franchise_Banner = ({onContactNowClick}) => {
  
  const [textIndex, setTextIndex] = useState(0);

  const handleContactNowClick = () => {
    if (onContactNowClick) {
      onContactNowClick();
    }
  };

 

  const textArray = [
    "Join us as franchise and be a  partner in our success story.",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % textArray.length);
    }, 700);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="franchise-container">
  

      <div className="franchise-headercontain-section">
      <div className="wrapper-container">
        <div className="franchise-container-section">
          <p className="franchise-para">Interested in a Franchise opportunity?</p>
          <div
            className="franchise-text-section"
            dangerouslySetInnerHTML={{ __html: textArray[textIndex] }}
          />

          <div className="franchise-btn-section">
            <button className="franchise-contact-btn" onClick={handleContactNowClick}>Contact Now</button>
          </div>
        </div>

      </div>
      </div>
      </div>
    </>
  );
};
export default Franchise_Banner;