import React, { useState, useEffect } from 'react';
import { Carousel, Card } from 'antd';
import Responsiveheaders from './Responsiveheadertest';
import Footer_community from './footer-community';
import TermsCondition_Header from './termscondition-header';
import TermsCondition_Content from './termscondition-content';

const { Meta } = Card;
const contentStyle = {
    margin: 0,
    height: '160px',
    color: '#fff',

};


const TermsCondition = () => {


    const onChange = (currentSlide) => {
        console.log(currentSlide);
    };
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        const menuList = document.querySelector('.menu-list');
        menuList.classList.toggle('show-mobile-menu');
    };




    return (
        <>


            <div className='centum'>

                <div className='body-content'>

                    <div>
                        < Responsiveheaders />
                    </div>
                    <div>
                        <TermsCondition_Header />
                    </div>
                    <div>
                        <TermsCondition_Content />
                    </div>
                    <div>
                        <Footer_community />
                    </div>



                </div>

            </div>











        </>
    );
}


export default TermsCondition;