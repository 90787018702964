import React from 'react';
import '../components/style/aboutus-management.scss';
import aboutManagementImg from '../asset/images/aboutManagementImg.png'


const AboutManagement = () => {

    return (
        <>
         

    <div className='about-management-container'>
    <div className='wrapper-container'>

      <div className='aboutus-centum-image'>
      <img src={aboutManagementImg} alt='Keystar' className="aboutus-centum" />
      </div>
      <div className='management-content'>
        <h2 className='management-header'>The Management</h2>
        <p className='management-sub-header'>CentumAPP</p>
        <p className='management-para'>The management of CentumAPP is overseen by a dedicated Board of Directors, consisting of esteemed educators who passionately believe that education is the key to addressing societal challenges. This makes CentumAPP unique as “An App made by Teachers”.</p>
            <p className='management-para'>Our management team is driven by the ambition to revolutionize education by integrating cutting-edge AI technology into our platform. Through features like Testmi, Coachmi, and community learning, we offer a unique and interactive learning experience for students.
                 Additionally, our Institution Partner Program aims to provide comprehensive support and guidance to 
                educational institutions, ensuring that students receive the right exposure and direction to 
                 excel in various examinations. With our commitment to innovation and collaboration, we strive to
                  empower budding minds with the tools and resources they need to succeed academically and beyond.</p>
        
      </div>
    </div>
    </div>


            
        </>
    );
}
export default AboutManagement;