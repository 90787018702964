import React, { useState } from 'react';
// import './style/emppower-landing.scss';
import './style/institution-govermentExam.scss'
import './style/hubschool-partner.scss';
import star from './../asset/images/Star 12.png';
import rocket from './../asset/images/rocketImage.png';
import governmentImage1 from '../asset/images/govermnetImage1.png';
import governmentImage2 from '../asset/images/governmentImage2.png';
import topcircleIMg from '../asset/images/hub-topCircle.png';
import bottomcircleIMg from '../asset/images/hub-bottomCircle.png';
import governmentImage3 from '../asset/images/governmentImg3.png'
import governmentImage from '../asset/images/governmentImg.png';
import governmentImage4 from '../asset/images/governmentImg4.png';
import hubBoyImg from '../asset/images/hubschl-boyimg.png';
import mblTopImage from '../asset/images/mobileTopImg.png';
import mblBottomImage from '../asset/images/mobileBottomImg.png';







const Hubschool_Partner = ({hubContactNowClick}) => {

    const handleHUbContactClick = () => {
        if (hubContactNowClick) {
          hubContactNowClick();
        }
      };

    return (
        <>  
                <div className="container-government hubschl-partner">
                <div className="wrapper-container">

                {/* <img src={governmentImage4} alt='governmentImage' className='government-halfstar-Image'/> */}
                <img src={topcircleIMg} alt='topcircleImg' className='topcircle-image'/>
                <img src={mblTopImage} alt='mblTopImage' className='mblTopImage-image'/>



                    <div className="government-content-btn hubschool-content-btn">
                        <p className="government-content-para">
                        Partner with CentumAPP for NEET/JEE Exam Prep!
                        </p>
                        <div className="government-button hubschool-button">
                         <a className="government-btn" onClick={handleHUbContactClick} >Contact Now </a>
                        </div>
                <img src={bottomcircleIMg} alt='bottomcircleImg' className='bottomcircle-image'/>
                <img src={mblBottomImage} alt='mblBottomImage' className='mblBottom-image'/>
                 
                    </div>
                <img src={governmentImage2} alt='governmentImage' className='government-star-Image hubs-star2-image blink' />
                <img src={governmentImage2} alt='governmentImage' className='government-star2-Image hubs-star-image blink'/>

                <img src={governmentImage3} alt='governmentImage' className='government-dots-Image hubs-dots-Image'/>
                  
                    <div className='second-governmentImage'>
                <img src={hubBoyImg} alt='governmentImage' className='government-Image hubs-boy-image'/>

                <img src={governmentImage1} alt='governmentmImage' className='government-mImage hubs-mImage-image'/>

                </div>
                </div>
                </div>
               
        </>
    )
}
export default Hubschool_Partner;