import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CountUp from 'react-countup';
import './style/categories-count-landing.scss';
import { getStatusFeatures } from './reducers/WebsiteSlice';
 

  
const Categories_count_landing =() =>{
   
  const { getStatusData, isLoading, totalCount } = useSelector((state) => state.WebsiteSlice);
 
  const dispatch = useDispatch();

  useEffect(() => {
      console.log("GetStatusFeatures.....",getStatusData);

      dispatch(getStatusFeatures());
  }, []);

    const categoryItems = [
        {
          title: 'Exam categories',
          count: 6,
        },
        {
          title: 'Mins. Watched',
          count: 3_200_000_000,
        },
        {
          title: 'Daily live classes',
          count: 1_500_000_000,
        },
        {
          title: 'Educators',
          count: 6_000_000,
        },
        {
          title: 'Video lessons',
          count: 1_000_000,
        },
      ];
    return(
    <>
         <div className="categories-container">
         <div className="wrapper-container">

      <div className="item-container">
        {getStatusData.map((item, index) => (
          <div className="category-item" key={index}>
            <div className="category-title">{item.title}</div>
            {/* Use CountUp component here */}
            <div className="category-count">
              <CountUp
                end={item.count}
                duration={4}
                formattingFn={(value) => {
                  // Format the count with M or B suffix without decimals
                  if (value >= 1_000_000_000) {
                    return `${Math.round(value / 1_000_000_000)}B`;
                  } else if (value >= 1_000_000) {
                    return `${Math.round(value / 1_000_000)}M`;
                  } else {
                    return `${value}`;
                  }
                }}
              />
              <span >+</span>
            </div>
          </div>
        ))}
      </div>
    </div>
</div>
 
    </>
    );
}
 
export default Categories_count_landing;
