import './App.css';
import Website from './components/website';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Route, Routes } from "react-router-dom";
import Community from './components/community';
import Community_ceuntum from './components/community-centum';
import Ceuntum_testMi from './components/centum-testMi';
import AboutUs from './components/about-us';
import PrivacyPolicy from './components/privacy-policy';
import TermsCondition from './components/terms-condition';
import CoachMi from './components/coachMi';
import NewTutorials from "./components/new-tutorials";
import Testi from './components/testimonials-community';
import InstitutionPartner from './components/institution-partner';
import Product from './components/product';
import SchoolsHub from './components/school-hubs';
import FranchisePage from './components/franchise-page';
const root = ReactDOM.createRoot(document.getElementById('root'));
function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<Website />} ></Route>
        <Route path='/website' element={<Website />} ></Route>
        <Route path='/community' element={<Community_ceuntum />}></Route>
        <Route path='/testMi' element={<Ceuntum_testMi />}></Route>
        <Route path='/aboutus' element={<AboutUs />}></Route>
        <Route path='/privacyPolicy' element={<PrivacyPolicy />}></Route>
        <Route path='/termsCondition' element={<TermsCondition />}></Route>
        <Route path='/coachMi' element={<CoachMi />}></Route>
        <Route path='/tuto' element={<NewTutorials/>}></Route>
        <Route path='/testi' element={<Testi />}></Route>
        <Route path='/product' element={<Product />}></Route>
        <Route path='/institutionPartner' element={<InstitutionPartner />}></Route>
        <Route path='/schoolsHub' element={<SchoolsHub />}></Route>
        <Route path='/franchisePage' element={<FranchisePage />}></Route>




        
      </Routes>
    </>
  );
}


export default App;
