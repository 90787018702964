import React, { useState } from 'react';
import './style/infobanner-community.scss';
import Star from '../asset/images/Star 12.png';
import Startwo from '../asset/images/Star 10.png';
import Leftstar from '../asset/images/Star 11.png';
import Rocket from '../asset/images/Layer_1.png';
import Keystar from '../asset/images/keystar.png';
import Pencile from '../asset/images/pencile.png';
import Lstar from '../asset/images/startwo.png';





const Infobanner_community = () => {

    return (
        <>
        <div className="overall-container-embrace">
        <div className="wrapper-container">
        <div className="info-container gradient-background">

            <div className="left-info-container">
                <div className="left-info-star">
                 <img src={Leftstar} alt='arroLeftstarw' className="star blink" style={{ animationDelay: '1s' }}/>
                </div>
                <div className="left-info-rocket">
                 <img src={Rocket} alt='Rocket' className="left-rocket" />
                </div>
                <div className="left-info-star-two">
                 <img src={Keystar} alt='Keystar' className="star blink" style={{ animationDelay: '2s' }}/>


                </div>

            </div>
            <div className="center-info-container">
                    <div className="info-star">
                 <img src={Star} alt='arrow' className="star blink" style={{ animationDelay: '3s' }}/>
                    </div>
                    <div className="info-hole-join">
                    <div className="info-power infobanner-color">
                    Embrace The Power Of Collective Knowledge, And Motivation As You Embark.
                    </div>
                    <div className="info-joinus infobanner-color">
                    Join us for exclusive access to valuable educational content, study material designed to propel your growth and success.
                    </div>
                    </div>                   
                    <div className="info-star-two">
                 <img src={Startwo} alt='arrow' className="star blink" style={{ animationDelay: '4s' }} />
                    </div>
                    </div>
                 

               <div className="right-info-container">
                <div className="info-img">
                    <div className="info-pencile">
                    <img src={Pencile} alt='arrow' className="pencile" />

                    </div>
                    <div className="hole-right-star">
                 <img src={Lstar} alt='arrow' className="star blink"  style={{ animationDelay: '5s' }}/>
                    
                    </div>
                </div>
               </div>


              </div>
            </div>
            </div>

        
       
           
            
        </>
    )
}
export default Infobanner_community;