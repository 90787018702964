import React, { useState, useEffect, useRef } from 'react';
import '../components/style/aboutus-mission.scss';
import missionImage from '../asset/images/missionImage.png';
import visionImage from '../asset/images/vissionImage.png';


const AboutMission = () => {

    return (
        <>

            <div className='about-mission-container-header'>
             <div className='wrapper-container'>
               
                <div className='about-mission-container'>
                    <h2>Mission & Vision</h2>
                    <p>Nurturing lifelong learning, empowering minds, and shaping future leaders with knowledge, opportunity, and excellence.</p>
                </div>




                <div className='about-mission-content'>
                    <div className='about-mission-cards'>
                        <img src={missionImage} alt='' className="aboutus-mission-Image" />
                        <h2>Mission</h2>
                        <p>At CentumAPP, our mission is to empower students to embrace a new era of exam preparation. We're dedicated to bridging the gap between traditional learning and modern success. Our commitment lies in providing a seamless, engaging, and holistic approach to education.</p>
                        <p>We strive to equip students with the tools and mindset they need to conquer exams. Our 'inside out' strategy focuses on daily prep-exams, enhancing confidence, retention, and recall. We are more than just an app; we're a steadfast partner in your journey toward academic excellence.</p>
                        <p>Our mission is to transform every student into a 100% winner, not by chance but by choice. Join us on this transformative path where success is a conscious decision. With CentumAPP, you're not just preparing for exams; you're preparing for a lifetime of success.</p>
                    </div>
                    <div className='about-mission-cards'>

                        <img src={visionImage} alt='' className="aboutus-mission-Image" />
                        <h2>Vision</h2>
                        <p>At CentumAPP, our vision is to revolutionize the way students prepare for exams. We believe in bridging the gap between preparation, revision, and exam day, ultimately transforming the entire exam preparation experience.</p>
                        <p>Our approach is simple yet groundbreaking. We equip candidates to remain deeply engaged with their syllabus, ensuring they are always in a state of readiness to excel in their exams. Through our platform, students unconsciously train their minds to master the intricate details of their study materials.</p>
                        <p>We employ a unique 'inside out' or 'reverse process' strategy, where the 'prep-exam' takes precedence over traditional preparation methods. Daily practice exams become the cornerstone of our approach, leading up to the actual exam day. This consistent practice not only boosts confidence but also enhances retention and recall of even the minutest details.</p>
                    </div>
                </div>
            </div>
          </div>


        </>
    );
}
export default AboutMission;