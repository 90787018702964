import React, { useEffect, useState, useRef } from "react";
import './style/hubschool-whycentum.scss';
import hubschoolGirlImg from  '../asset/images/hubschoolgirl.png';
import dymandLogo from '../asset/images/hubdymand.png';
import franchisemenImg from '../asset/images/franchisemenImg.png'



const Franchise_Whycentum = () => {
   
    const hubCentumData = [
        {
          imgSrc: dymandLogo,
          text: 'Opportunity to get associated with India’s leading AI driver app based platform.',
        },
        {
          imgSrc: dymandLogo,
          text: 'We have an excellent system of operations for a successful business venture.',
        },
        {
          imgSrc: dymandLogo,
          text: 'We provide end to end franchise setup assistance.',
        },
        {
            imgSrc: dymandLogo,
            text: 'We offer Attractive,, Qualitative and most affordable courses to the students.',
          },
          {
            imgSrc: dymandLogo,
            text: 'We have a pool of faculties with vast experience for different exams like TNPSC, UPSC, NEET, NET English and SSC.',
          },
          {
            imgSrc: dymandLogo,
            text: 'We provide valuable study material and productive curriculum developed by a dedicated team off researchers.',
          },
          {
            imgSrc: dymandLogo,
            text: 'We provide excellent support in Marketing & Branding',
          },
          {
            imgSrc: dymandLogo,
            text: 'We provide Complete Assistance in Selecting & Training Faculties.',
          },
          {
            imgSrc: dymandLogo,
            text:'Most important is Our Franchise offers high returns with low investment and working capital',
          },
      ];



  return (
    <>

    <div className="hubschool-whycentum franchise-whycentum">
    <div className="wrapper-container">

    <div className="hubschool-whycentum-image">
        <img src={franchisemenImg} alt='franchisemenImg' className="hubschoolGirlImg-image" />
      
        </div>
        <div className="hubschool-whycentum-content">
            <p className="hubschool-header">Why CentumAPP ?</p>

            <div className='hubschool-para-container'>
        {hubCentumData.map((card, index) => (
          <div key={index} className="hubschool-image-loop">
            <img src={card.imgSrc} alt={card.alt} />
            <p className='paratext'>{card.text}</p>
          </div>
        ))}
      </div>
          
        </div>
        </div>
    </div>

    </>
  );
};
export default Franchise_Whycentum;
