import React, { useEffect } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from 'jquery';
import "slick-carousel/slick/slick.min.js";
// import './style/Landing-our-top-tutorial.scss';
import './style/landing-our-top-tutorial.scss';
import TutorialIcon from "../../src/asset/images/tutorial1.png";
import { useDispatch, useSelector } from 'react-redux';
import { getTutorial } from '../components/reducers/WebsiteSlice';
import Slider from 'react-slick';

// import './style/landing-our-top-tutorial.scss';

function NewTutorials() {

    const { getTutorialData, isLoading, totalDataCount } = useSelector((state) => state.WebsiteSlice);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getTutorial()).unwrap().then((res) => {
        });
    }, []);

    const settings = {
        centerMode: true,
        centerPadding: '10px',
        slidesToShow: 3,
        speed: 500,
        slidesToScroll: 1,
        arrows: true,
        dots: true,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    arrows: false,
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    arrows: false,
                    slidesToShow: 2
                },
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    dots: false,
                    slidesToShow: 1
                },
            },
        ],
    };

    const renderSlides = getTutorialData.map((d) => (
        <>
           <div key={d.id} className="tutorial-card">
                <div className="tutorial-card-img">
                    <a href={d.videoLink} target="_blank" rel="noopener noreferrer">
                        <img src={d.videoThumbnailUrl} alt="" srcSet="" />
                    </a>

                </div>
                <div className="tutorial-content">
                    <div className="tutorial-content-text-head">{d.name}</div>
                    <div className="tutorial-content-text">{d.examName}</div>
                </div>
            </div>

        </>
    ))


    return (

        <div className="wrapper">
<div className='wrapper-container'>
            <div className='sub-tutorial'>
                <h1>Our Top Tutorial</h1>
                <p>Discover valuable insights with our top-notch tutorials led by expert instructors.</p>
            </div>




            {/* <div className="my-slider">
            
            </div> */}
            {/* <section className="center slider"> */}
                <div className='slider-center-container'>
                    <Slider {...settings}>{renderSlides}</Slider>
                </div>
            {/* </section> */}

        </div>


</div>


    )
}
export default NewTutorials;
