import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './style/join-centumapp.scss'
import star from './../asset/images/star.png';
import tnspc from './../asset/images/tnpscImage.png';
import telegram from './../asset/images/telegramImag.png';
import whatsapp from './../asset/images/WhatsApp.png';
import youtube from './../asset/images/YouTube.png';
import instagram from './../asset/images/Instagram.png';
import ssctamil from './../asset/images/sscImage.png';
import upsc from './../asset/images/upscImage.png';
import neet from './../asset/images/neetImage.png';
import mobile from './../asset/images/gif_mobile.gif';
import { Carousel, Card } from 'antd';
import { getCommunity } from './reducers/WebsiteSlice';




const Join_centumapp = () => {

    const { getCommunityData, isLoading, totalCount } = useSelector((state) => state.WebsiteSlice);
 
    const dispatch = useDispatch();
  
    useEffect(() => {
        console.log("getCurrentAffairs...",getCommunityData);
  
        dispatch(getCommunity());
    }, []);
    const joinCourse=[{name:'TNPSC'},{name:'NEET'},{name:'SESC'},{name:'TNPSC'},{name:'UCET'},{name:'TNPSC'}]
    return (
        <>
            <div className='join-container'>
            <div className='wrapper-container'>

                <div className="join-leftcontainer">
                    <div className="join-centum">
                        <div className="join-centumapp">
                            <div className='heading'>
                                <h1 className='join-heading'>Join Our CentumApp Community</h1>
                                <img src={star} alt="star" className='blinks' style={{ animationDelay: '1s' }} />
                            </div>
                            <p className='join-para'>Points of community</p>
                        </div>
                        <div className="join-course">
                            {getCommunityData.map((course, index) => (
                                <div className='join-courses' key={index}>
                                    <div className ='join-course-card'>
                                        <div className='courseHeading'>
                                            <div className='courseHeadingImage'>
                                                <img src={course.logoUrl} alt="image" />
                                            </div>
                                            <p>{course.name}</p>
                                        </div>
                                        <div className="line"></div>
                                        <div className='social-container'>
                                            {course.socialMedia.map((social, index) => (
                                                <div className="socialMedia" key={index}>
                                                    <div className="socialLink">
                                                        <img src={social.socialMediaUrl} alt="telegram" />
                                                        <a href={social.socialMediaLink} className='socialMediaNmae'>{social.name}</a>
                                                    </div>

                                                </div>
                                            ))}
                                        </div>
                                    </div>


                                </div>
                            ))}
                        </div>

                    </div>
                    
                    <div className='join-centumapp-image'>
                        <img src={star} className='blinks' alt="star"  style={{ animationDelay: '2s' }}/>
                    </div>
                </div>
                <div className="join-landing-rightcontainer">
                        <img src={mobile} alt="" />
                </div>
            </div>
            </div>
        </>
    )
}
export default Join_centumapp;