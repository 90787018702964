import React, { useEffect, useState, useRef } from "react";
// import './style/hubSchool_Beneficiaries.scss';
import './style/hubschool_Beneficiaries.scss'
import institutiuon_Beneficiaries from '../asset/images/beneficiersIage.png';
import hubBenificial_image from '../asset/images/hub-beneficialImg.png'
import * as yup from "yup";
import { Formik, useFormik } from "formik";
import { Button, Modal, Form, Input,message } from "antd";
import franchiseOpportunity from '../asset/images/franchiseopportunity.png';
import franchiseBaloon from '../asset/images/franchiseBaloon.png';
import franchiseFlag from '../asset/images/franchiseFlag.png';
import franchiseLocation from '../asset/images/franchiseLocation.png';
import franchisePin from '../asset/images/franchisePin.png';
import franchiseSideimg from '../asset/images/franchiseSideimg.png';
import { useDispatch, useSelector } from 'react-redux';
import {institutioncontactregister} from '../components/reducers/WebsiteSlice'






const Franchise_Opportunity = () => {
 
  const dispatch = useDispatch();
    const initialValues = {
        name: "",
        colleageName: "",
        mobileNumber: "",
        email: "",
        message:"",
      };
    
      const validationSchema = yup.object().shape({
        name: yup.string().required("Name is required"),
        colleageName: yup.string().required("College/School name is required"),
        mobileNumber: yup
          .string()
          .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits")
          .required("Mobile number is required"),
        email: yup.string().email("Invalid email").required("Email is required"),
        message: yup.string().required("message is required"),

      });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
          // setIsModalOpen(false);
          dispatch(institutioncontactregister(values)).unwrap().then((res) =>{
            if(res){
              message.success(res.message)
              formik.resetForm()
            }
          });
        },
      });

  useEffect(() => {
    
  }, []);

  return (
    <>
    <div className="institution-whole-container">
    <div className="wrapper-container">
        <div className="service-institution-content">
            <div className="Institutiuon-service-header">
                <h2>Franchise Opportunity </h2>
                <p>Our Franchise offers high returns with low investment and working capital, We invite professional with high entrepreneurial aspirations to partner and have great earnings.</p>
            </div>
            <div className="Institutiuon-service-image">
          <img src={franchiseOpportunity} alt="arrow" className="institutiuon_Beneficiaries-image franchiseOpp-image" />
          <img src={franchiseBaloon} alt="arrow" className="franchiseBaloon-image" />
          <img src={franchiseFlag} alt="arrow" className="franchiseFlag-image" />
          <img src={franchiseLocation} alt="arrow" className="franchiseLocation-image" />
          <img src={franchiseLocation} alt="arrow" className="franchise-Location-image" />
          <img src={franchisePin} alt="arrow" className="franchisepin-image1" />
          <img src={franchiseSideimg} alt="arrow" className="franchisepin" />


             
            </div>
        </div>
        <div className="institution-beneficiaries-form franchise-beneficiaries-form">
        <div className="beneficial-content" style={{ display: "flex" }}>
                    <div className="service-institution-right">
                      <p className="register-institution" style={{ textAlign: "center" }}>
                      Ready to franchise? Let's connect!
                      </p>
                      <Formik
                        validateOnChange={false}
                        validateOnBlur={false}
                        style={{ gap: "0px" }}
                      >
                        <Form onSubmit={formik.handleSubmit}>
                          <div className="formgroup-institution">
                            <label htmlFor="name">
                            Your Name<span style={{ color: "red" }}> *</span>
                            </label>
                            <div className="service-fields-group">
                              <Input
                                type="text"
                                id="name"
                                placeholder="Enter Your Name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                              />
                              {formik.touched.name && formik.errors.name ? (
                                <div
                                  className="errorMessage"
                                  style={{ color: "red" }}
                                >
                                  {formik.errors.name}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="formgroup-institution">
                            <label htmlFor="colleageName">
                              College/School Name
                              <span style={{ color: "red" }}> *</span>
                            </label>
                            <div className="service-fields-group">
                              <Input
                                type="text"
                                id="colleageName"
                                placeholder="Enter Your College/School Name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.colleageName}
                              />
                              {formik.touched.colleageName &&
                              formik.errors.colleageName ? (
                                <div
                                  className="errorMessage"
                                  style={{ color: "red" }}
                                >
                                  {formik.errors.colleageName}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="formgroup-institution">
                            <label htmlFor="mobileNumber">
                              Mobile Number
                              <span style={{ color: "red" }}> *</span>
                            </label>
                            <div className="service-fields-group">
                              <Input
                                type="text"
                                id="mobileNumber"
                                placeholder="Enter Your Mobile Number"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.mobileNumber}
                              />
                              {formik.touched.mobileNumber &&
                              formik.errors.mobileNumber ? (
                                <div
                                  className="errorMessage"
                                  style={{ color: "red" }}
                                >
                                  {formik.errors.mobileNumber}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="formgroup-institution">
                            <label htmlFor="email">
                            Email ID
                              <span style={{ color: "red" }}> *</span>
                            </label>
                            <div className="service-fields-group">
                              <Input
                                type="email"
                                id="email"
                                placeholder="Enter Your Email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                              />
                              {formik.touched.email && formik.errors.email ? (
                                <div
                                  className="errorMessage"
                                  style={{ color: "red" }}
                                >
                                  {formik.errors.email}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="formgroup-institution">
                            <label htmlFor="message">
                            Message
                              <span style={{ color: "red" }}> *</span>
                            </label>
                            <div className="service-fields-group">
                              <Input
                                type="message"
                                id="message"
                                placeholder="Enter Your Message"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.message}
                              />
                              {formik.touched.message && formik.errors.message ? (
                                <div
                                  className="errorMessage"
                                  style={{ color: "red" }}
                                >
                                  {formik.errors.message}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <button type="text-institution" onClick={formik.handleSubmit}>
                            Submit
                          </button>
                        </Form>
                      </Formik>
                    </div>
                  </div>
        </div>
    </div>
    </div>
      
    </>
  );
};
export default Franchise_Opportunity;
